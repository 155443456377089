import { combineReducers } from 'redux';
import homeReducer from './homeSlice';
import authReducers from './authSlice';
import siteCodeSlice from './siteCodeSlice';
import RmsStationSlice from './RmsStationSlice';
import RmsAlarmGroupSlice from './RmsAlarmGroupSlice';
import LockAlarmListSlice from './LockAlarmListSlice';
import AlarmSiteCodeSlice from './AlarmSiteCodeSlice';
import TimelineSlice from './TimelineSlice';
import LockAlarmHistorySlice from './LockAlarmHistorySlice';
import RmsAlarmHistorySlice from './RmsAlarmHistorySlice';
import DoorSlice from './DoorSlice';
import DoorStatusSlice from './DoorStatusSlice';
import DoorAlarmTypeSlice from './DoorAlarmTypeSlice';
import RmsAlarmTypeSlice from './RmsAlarmTypeSlice';
import NotifySlice from './NotifySlice';
import RmsActiveAlarmSlice from './RmsActiveAlarmSlice';
import AcsActiveAlarmSlice from './AcsActiveAlarmSlice';
import DoorSiteCodeSlice from './DoorSiteCodeSlice';
import SiteIdSlice from './SiteIdSlice';
import DoorDashboardSlice from './DoorDashboardSlice';
import SiteAssetSlice from './SiteAssetSlice';
import batteryIDSlice from './batteryIDSlice';
import acmIDSlice from './acmIDSlice';
import RectifierIDSlice from './RectifierIDSlice';
import offlineIDSlice from './offlineIDSlice';
import JobSlice from './JobSlice';
import VendorSlice from './VendorSlice';
import VendorUserSlice from './VendorUserSlice';
export default combineReducers({
    homeReducer:homeReducer,
    authReducer:authReducers,
    siteCodeReducer:siteCodeSlice,
    RmsStationReducer:RmsStationSlice,
    rmsAlarmGroupReducer:RmsAlarmGroupSlice,
    lockAlarmListReducer:LockAlarmListSlice,
    alarmSiteCodeReducer:AlarmSiteCodeSlice,
    timelineSlice:TimelineSlice,
    lockAlarmHistoryReducer:LockAlarmHistorySlice,
    rmsAlarmHistoryReducer:RmsAlarmHistorySlice,
    doorReducer:DoorSlice,
    doorStatusReducer:DoorStatusSlice,
    doorAlarmTypeReducer:DoorAlarmTypeSlice,
    rmsAlarmTypeReducer:RmsAlarmTypeSlice,
    notifyReducer:NotifySlice,
    rmsActiveAlarmSlice:RmsActiveAlarmSlice,
    acsActiveAlarmSlice:AcsActiveAlarmSlice,
    doorSiteCodeReducer:DoorSiteCodeSlice,
    siteIdReducer:SiteIdSlice,
    doorDashboardReducer:DoorDashboardSlice,
    siteAssetReducer:SiteAssetSlice,
    batteryIDReducer:batteryIDSlice,
    acmIDReducer:acmIDSlice,
    rectifierIDReducer:RectifierIDSlice,
    offlineIDReducer:offlineIDSlice,
    jobReducer:JobSlice,
    vendorReducer:VendorSlice,
    vendorUserReducer:VendorUserSlice,
});