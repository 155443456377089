import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { SITE_ID_FETCH_SUCCESS } from "../../Reducer/SiteIdSlice";
import { DateRange } from "react-date-range";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import API from "../../Network/API";
import UnlockDoor from "../../Models/UnlockDoor";
import RmsDashboardTrProps from "../../Props/RmsDashboardTrProps";
import UnlockDoorSite from "../../Models/UnlockDoorSite";
import { DOOR_DASHBOARD_FETCH_SUCCESS } from "../../Reducer/DoorDashboardSlice";
import RectifierCard from "./props/RectifierCard";
import TenantInfo from "./props/TenantInfo";
import ACPower from "./props/ACPower";
import BatteryInfo from "./props/BatteryInfo";
export default function SiteDashboard() {
    const [sites, setSite] = useState()
    const [rms, setRms] = useState()
    const [modalDialog, setModalDialog] = useState(false);
    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const ddd = useParams();
    const dispatch = useDispatch()

    useEffect(() => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');

        API.get('/v1/oauth/site/' + ddd.id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            dispatch(DOOR_DASHBOARD_FETCH_SUCCESS(response.data.data.rms.site))
            setSite(response.data.data.rms.site)
            setRms(response.data.data.rms)

        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))

    }, [ddd.id]);

    const site_select = useSelector((state) => state.doorDashboardReducer)
    const site = site_select?.main_data
    return (
        <>
            <div className="row ps-1 me-1 mt-1">
                <div className="col-md-8">
                    <div className="card rounded-1">
                        <div class="card-header  border-bottom card-title bg-white">
                            <a href="#" class="rms_table_heading_text">{ddd.id} (<i class={`fa fa-circle ${rms?.site?.device_live_status == 1 ? 'text-success' : 'text-danger'} `}></i> {site?.device_live_status == 1 ? 'Online' : 'Offline'})</a>
                            <p className="p-0 m-0" style={{ fontSize: '11px', color: 'blue' }}>Last Update:{rms?.update_time}</p>
                        </div>
                        <div className="tab-box1 active" id="tab1-1">
                            <table className="table table-stripedd table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom">
                                <tbody>
                                    <tr>
                                        <th>Cluster</th>
                                        <td>{rms?.cluster_name}</td>
                                        <th>Zone</th>
                                        <td>{rms?.zone_name}</td>
                                        <th>Field Engineer</th>
                                        <td> {site?.master_site?.field_engineer} ({site?.master_site?.field_engineer_phone}) </td>
                                    </tr>
                                    <tr>
                                        <th>Site ID</th>
                                        <td>{site?.site_id}</td>
                                        <th>EASI SITE ID</th>
                                        <td>{site?.customer_site_id}</td>
                                        <th>Site Name</th>
                                        <td>{site?.site_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Site Category</th>
                                        <td>{rms?.category_name}</td>
                                        <th>Device SN</th>
                                        <td>{rms?.device_id}</td>
                                        <th>Active SIM</th>
                                        <td>{rms?.site?.phone_number}</td>
                                    </tr>
                                    <tr>
                                        <th>IMEI</th>
                                        <td>{rms?.site?.imei}</td>
                                        <th width="20%">Hardware Version</th>
                                        <td>{rms?.site?.hardware_version}</td>
                                        <th>Firmware version</th>
                                        <td>{rms?.site?.firmware_version}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">RMS Installation</th>
                                        <td>{rms?.site?.installation_date}</td>
                                        <th>Commission date</th>
                                        <td></td>
                                        <th>PAT Date</th>
                                        <td width="20%"></td>
                                    </tr>
                                    <tr>
                                        <th>Latitude</th>
                                        <td>{rms?.site?.lat}</td>
                                        <th>Longitude</th>
                                        <td>{rms?.site?.lang}</td>
                                        <th width="20%">TEMPERATURE</th>
                                        <td>
                                            0°C
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Address</th>
                                        <td colSpan={5}>{rms?.site?.master_site?.address}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className="col-md-4">


                    <div className="row mt-1 mb-2">
                        <div className="card">
                            <div className="card-body d-flex justify-content-between">
                                <div className="text-center">
                                    <div className="m-auto door-icon bg-orange shadow-green1" title="Battery Backup">
                                        <i className="fa-regular fa-battery-full icon text-white" style={{ fontSize: '25px' }}></i>
                                    </div>
                                    <p className="m-0 text-backup-title" style={{ fontWeight: '600' }}>{rms?.backup_time}</p>
                                    <p className="m-0 text-backup">Battery Backup</p>
                                </div>
                                <div className="text-center">
                                    <div className="m-auto door-icon bg-warning shadow-green1" title="Remaining Backup">
                                        <i className="fa-regular fa-battery-three-quarters icon text-white" style={{ fontSize: '25px' }}></i>

                                    </div>
                                    <p className="m-0 text-backup-title" style={{ fontWeight: '600' }}>{rms?.backup_hour}</p>
                                    <p className="m-0 text-backup">Remaining Backup</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row mb-2">
                        <div className="card-box" style={{ marginBottom: '10px' }}>
                            <h4 className="header-title mb-0" style={{ padding: '3px', fontSize: '12px', paddingBottom: '10px' }}>AC Power Parameter</h4>
                            <hr style={{ marginTop: '0px', marginBottom: '3px' }} />
                            <table className="table table-striped table-bordered table-centered mb-0  mt-1 alarm_table_custom">
                                <thead className="toi-primary text-white">
                                    <tr>
                                        <th className="text-center">Phase</th>
                                        <th className="text-center" width="15%">Active</th>
                                        <th className="text-center" width="15%">Missing</th>
                                        <th className="text-center" width="15%">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center" style={{ background: 'red', color: '#f9f7f7', fontWeight: 'bold' }}>R</td>
                                        <td className="text-center">
                                            <div className={`alarm_icon  ${rms?.portConfiguration?.unknown_1 == '' ? 'A4' : 'A1'} `} title=" Active ">&nbsp;</div>
                                        </td>
                                        <td className="text-center">
                                            <div className={`alarm_icon  ${rms?.unknown_1 == 0 ? 'A1' : 'A3'} `} title=" Active ">&nbsp;</div>
                                        </td>
                                        <td className="text-center">
                                            <div className={`alarm_icon  ${rms?.unknown_1 == 0 ? 'A1' : 'A3'} `} title=" Active ">&nbsp;</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" style={{ background: 'yellow', color: '#000', fontWeight: 800 }}>Y</td>
                                        <td className="text-center">
                                            <div className={`alarm_icon  ${rms?.portConfiguration?.unknown_2 == '' ? 'A4' : 'A1'} `} title=" Active ">&nbsp;</div>
                                        </td>
                                        <td className="text-center">
                                            <div className={`alarm_icon  ${rms?.unknown_2 == 0 ? 'A1' : 'A3'} `} title=" Active ">&nbsp;</div>
                                        </td>
                                        <td className="text-center">
                                            <div className={`alarm_icon  ${rms?.unknown_2 == 0 ? 'A1' : 'A3'} `} title=" Active ">&nbsp;</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center" style={{ background: 'blue', color: '#fff', fontWeight: 800 }}>B</td>
                                        <td className="text-center">
                                            <div className={`alarm_icon  ${rms?.portConfiguration?.unknown_3 == '' ? 'A4' : 'A1'} `} title=" Active ">&nbsp;</div>
                                        </td>
                                        <td className="text-center">
                                            <div className={`alarm_icon  ${rms?.unknown_3 == 0 ? 'A1' : 'A3'} `} title=" Active ">&nbsp;</div>
                                        </td>
                                        <td className="text-center">
                                            <div className={`alarm_icon  ${rms?.unknown_3 == 0 ? 'A1' : 'A3'} `} title=" Active ">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className="col-xl-4 col-lg-4 ">
                    <div className="row ms-1">


                        <div className="card-box" style={{ marginBottom: '10px', minHeight: '388px' }}>
                            <h4 className="header-title mb-0" style={{ padding: '3px', fontSize: '12px', paddingBottom: '10px' }}>Alarm Overview
                            </h4>
                            <hr style={{ marginTop: '0px', marginBottom: '3px' }} />


                            <div class="custom-tab">
                                <nav>
                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Critical</button>
                                        <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Major</button>
                                        <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Minor</button>
                                    </div>
                                </nav>
                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                                        <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom">
                                            <thead className="toi-primary text-white">
                                                <tr>
                                                    <th className="text-center">Alarm Name</th>
                                                    <th className="text-center">Generated At</th>
                                                    <th className="text-center">Elapsed Time</th>
                                                    <th className="text-center">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <RmsDashboardTrProps title={`DOOR OPEN`} startDate={rms?.door_open_time} status={rms?.door_open} />
                                                <RmsDashboardTrProps title={`DC Low`} startDate={rms?.dc_low_time} status={rms?.dc_low} />
                                                <RmsDashboardTrProps title={`PG Running`} startDate={rms?.dc_low_time} status={rms?.pg_status} />
                                                <RmsDashboardTrProps title={`LLVD`} startDate={rms?.llvd_start_time} status={rms?.llvd} />
                                                <RmsDashboardTrProps title={`Robi Site Down`} startDate={rms?.llvd_start_time} status={rms?.llvd} />
                                                <RmsDashboardTrProps title={`GP Site Down`} startDate={rms?.llvd_start_time} status={rms?.llvd} />
                                                <RmsDashboardTrProps title={`BL Site Down`} startDate={rms?.llvd_start_time} status={rms?.llvd} />
                                                <RmsDashboardTrProps title={`Teletalk Site Down`} startDate={rms?.llvd_start_time} status={rms?.llvd} />

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                                        <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom">
                                            <thead className="toi-primary text-white">
                                                <tr>
                                                    <th className="text-center">Alarm Name</th>
                                                    <th className="text-center">Generated At</th>
                                                    <th className="text-center">Elapsed Time</th>
                                                    <th className="text-center">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <RmsDashboardTrProps title={`Mains Fail`} startDate={rms?.mains_fail_time} status={rms?.mains_fail} />
                                                <RmsDashboardTrProps title={`MDB FAULT`} startDate={rms?.mdb_fault_time} status={rms?.mdb_fault} />
                                                <RmsDashboardTrProps title={`MODULE FAULT`} startDate={rms?.rectifier_fault_time} status={rms?.rectifier_fault} />
                                                <RmsDashboardTrProps title={`WATER LEAKAGE`} startDate={rms?.water_leakage_time} status={rms?.water_leakage} />
                                                <RmsDashboardTrProps title={`SMOKE`} startDate={rms?.smoke_time} status={rms?.smoke} />
                                                <RmsDashboardTrProps title={`DC FAN FAULT`} startDate={rms?.dc_fan_fault_time} status={rms?.dc_fan_fault} />
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
                                        <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom">
                                            <thead className="toi-primary text-white">
                                                <tr>
                                                    <th className="text-center">Alarm Name</th>
                                                    <th className="text-center">Generated At</th>
                                                    <th className="text-center">Elapsed Time</th>
                                                    <th className="text-center">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <RmsDashboardTrProps title={`Overloaded`} startDate={rms?.aviation_light_fault_time} status={rms?.aviation_light_fault} />
                                                <RmsDashboardTrProps title={`Load Disconnect`} startDate={rms?.aviation_light_fault_time} status={rms?.aviation_light_fault} />
                                                <RmsDashboardTrProps title={`AVIATION LIGHT FAULT`} startDate={rms?.aviation_light_fault_time} status={rms?.aviation_light_fault} />
                                                <RmsDashboardTrProps title={`ODC TEMPERATURE HIGH`} startDate={rms?.odc_temp_high_time} status={rms?.odc_temp_high} />
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>



                        </div>

                        <BatteryInfo rms={rms}/>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4">
                    <div className="card-box" style={{ marginBottom: '10px' }}>
                        <h4 className="header-title mb-0" style={{ padding: '3px', fontSize: '12px', paddingBottom: '10px' }}>DC Power Parameter
                        </h4>
                        <hr style={{ marginTop: '0px', marginBottom: '3px' }} />
                        <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom">
                            <thead className="toi-primary text-white">
                                <tr>
                                    <th className="text-left">Source</th>
                                    <th className="text-center"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {rms?.dc_energy_meter.length > 0 && rms?.dc_energy_meter.map((tenant, index) => (
                                    <tr>
                                        <td className="text-left">DC-{index + 1} Voltage</td>
                                        <td className="text-center">
                                            {tenant?.voltage} V
                                        </td>
                                    </tr>

                                ))}

                                <tr>
                                    <td className="text-left">Current</td>
                                    <td className="text-center">
                                        {rms?.dc_current} A
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="card-box" style={{ marginBottom: '10px' }}>
                        <h4 className="header-title mb-0" style={{ padding: '3px', fontSize: '12px', paddingBottom: '10px' }}>Energy Consumption
                            (Today)</h4>
                        <hr style={{ marginTop: '0px', marginBottom: '3px' }} />
                        <table className="table table-striped table-bordered table-centered mb-0 text-uppercase mt-1 alarm_table_custom">
                            <thead className="toi-primary text-white">
                                <tr>
                                    <th className="text-left">Source</th>
                                    <th className="text-center">Run Hour(HH:MM:SS)</th>
                                    <th className="text-center" width="10%" />
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-left">Grid Power</td>
                                    <td className="text-center">{rms?.grid_run_hour}</td>
                                    <td className="text-center">
                                        <div className={`alarm_icon  ${rms?.mains_fail == 1 ? 'A4' : 'A1'}`}>&nbsp;</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left">Battery</td>
                                    <td className="text-center">{rms?.battery_run_hour}</td>
                                    <td className="text-center">
                                        <div className={`alarm_icon  ${rms?.mains_fail == 1 && rms?.pg_status == 0 ? 'A1' : 'A4'}`}>&nbsp;</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left">Generator</td>
                                    <td className="text-center">{rms?.generator_run_hour}</td>
                                    <td className="text-center">
                                        <div className={`alarm_icon  ${rms?.pg_status == 1 ? 'A1' : 'A4'}`}>&nbsp;</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {rms?.acm?.length > 0 && rms?.acm?.map((row, index) => (
                        <ACPower key={index} row={row}/>
                    ))}

                  
                </div>
                <div className="col-xl-4 col-md-4 col-lg-4">
                  <TenantInfo rms={rms}/>

                    {rms?.rectifer?.length > 0 && rms?.rectifer?.map((row, index) => (
                    <RectifierCard row={row}/>
                    ))}
                </div>

            </div>

        </>

    )
}