import { createSlice } from '@reduxjs/toolkit';

export const RmsAlarmHistorySlice = createSlice({
    name: 'RmsAlarmHistory',
    initialState: {
        loading: false,
        data: {

        },
        error: false,
    },
    reducers: {
        RMS_ALARM_HISTORY_FETCH_START: (state) => {
            state.loading = true
        },
        RMS_ALARM_HISTORY_FETCH_SUCCESS: (state, action) => {
            state.loading = false
            state.data = action.payload
        },
        RMS_ALARM_HISTORY_FETCH_ERROR: (state) => {
            state.error = true
        }
    }
});

export const { RMS_ALARM_HISTORY_FETCH_START, RMS_ALARM_HISTORY_FETCH_SUCCESS, RMS_ALARM_HISTORY_FETCH_ERROR } = RmsAlarmHistorySlice.actions;
export default RmsAlarmHistorySlice.reducer;