import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import API from "../../Network/API";
import Paginate from "../../CustomHook/Paginate";
import { Link } from "react-router-dom";

export default function RectifierIndex() {

    const [nxtPage, setNextPage] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [main_data, setMainData] = useState([]);
    const [site_status, setStatus] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        syncData('');
    }, []);


    const paginate = (pageNumber) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/rectifier_monitoring?page=' + pageNumber).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            setMainData(response.data.data)
            setStatus(response.data.data.site_status)
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    };

    useEffect(() => {
        if (nxtPage != null) {
            const loaderElement = document.querySelector(".loading-mask");
            loaderElement.removeAttribute('hidden');
            loaderElement.setAttribute('visible', 'true');
            API.get(nxtPage).then((response) => {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
                setMainData(response.data.data)
            }).catch((error) => {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            });
        }

    }, [nxtPage]);

    const previousPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const nextPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const syncData = (name) => {
        setLoading(true)
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/rectifier_monitoring?site_id=' + name).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            setMainData(response.data.data)
            setLoading(false)
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }
    return (
        <>
            <div className="row text-center  mb-2 mt-2 me-1 ms-1">
                <div className="d-flex justify-content-end">
                    <div class="input-group input-group-sm max-with-searbar">
                        <input onChange={(e) => syncData(e.target.value)} type="text" class="form-control" placeholder="search by site id...." />
                        <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                    </div>
                    <button onClick={() => syncData('')} class="btn btn-warning  ms-2" type="button" ><i class="fa-light fa-refresh me-1" />Sync</button>
                </div>
            </div>

            <div className="table-responsive mt-2">
                <table className="custom_table m-0 table-sm table-custom dcEnergy bg-white table-striped table-bordered">
                    <thead>
                        <tr className="text-uppercase">
                            <th className="text-center toi-primary" rowSpan={2}>Updated</th>
                            <th className="text-center toi-primary" rowSpan={2}>SITE ID</th>
                            <th className="text-center toi-primary" rowSpan={2}>Easi SITE ID</th>
                            <th className="text-center toi-primary" rowSpan={2}>Rectifer Name</th>
                            <th className="text-center toi-primary" rowSpan={2}>Network</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>Mains Fail</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>DC Low</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>Module Fault</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>Fuse Fault</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>LLVD</th>
                            <th className="text-center toi-primary w-20" rowSpan={2}>BLVD</th>
                            <th className="text-center toi-primary" colSpan={2}>DC</th>
                            <th className="text-center toi-primary" colSpan={6}>Phase</th>
                            <th className="text-center toi-primary" rowSpan={2}>AC frequency</th>
                            <th className="text-center toi-primary" colSpan={2}>Cumulative Power</th>
                        </tr>
                        <tr>
                            <th className="text-center toi-primary">V</th>
                            <th className="text-center toi-primary">I</th>
                            <th className="text-center toi-primary">A(V)</th>
                            <th className="text-center toi-primary">B(V)</th>
                            <th className="text-center toi-primary">C(V)</th>
                            <th className="text-center toi-primary">A(I)</th>
                            <th className="text-center toi-primary">B(I)</th>
                            <th className="text-center toi-primary">C(I)</th>
                            <th className="text-center toi-primary">AC(kWh)</th>
                            <th className="text-center toi-primary">DC(kWh)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && main_data?.sites?.map((site, index) => (
                            <tr className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`}>
                                <td>
                                <p className="m-0 p-0" style={{fontSize:'12px',width:'150px'}}>{site.update_time}</p>
                                        
                                </td>

                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.site_id}</Link>
                                </td>
                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.customer_site_id}</Link>
                                </td>

                                <td className="text-center">
                                    <a className="text-warning text-underline-hover" href="#"> {site.name}</a>

                                </td>
                                <td className="text-center">
                                    {site.device_live_status === 1 ?
                                        <div className="signal-bars mt1 sizing-box good four-bars">
                                            <span>
                                                <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                                            </span>
                                            <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                                            </div>
                                        </div>
                                        :
                                        <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                                    }
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon ${site.mains_fail==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon ${site.dc_low==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon ${site.rectifier_fault==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon ${site.bat_load_fuse_fault==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon   ${site.llvd==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    <div className={`alarm_icon   ${site.critical_disconnect==1?'A3':'A1'}`}>
                                        &nbsp;
                                    </div>
                                </td>
                                <td className="text-center">
                                    {site.dc_voltage}
                                </td>
                                <td className="text-center">
                                {site.dc_current}
                                </td>
                                <td className="text-center">
                                {site.pahse_avoltage}
                                </td>
                                <td className="text-center">
                                {site.pahse_bvoltage}
                                </td>
                                <td className="text-center">
                                {site.pahse_cvoltage}
                                </td>
                                <td className="text-center">
                                {site.pahse_acurrent}
                                </td>
                                <td className="text-center">
                                {site.pahse_bcurrent}
                                </td>
                                <td className="text-center">
                                {site.pahse_ccurrent}
                                </td>
                                <td className="text-center">
                                {site.ac_frequency}
                                </td>
                                <td className="text-center">
                                {site.cum_ac_power}
                                </td>
                                <td className="text-center">
                                {site.cum_dc_power}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={main_data?.prv_page_url} nextPageUrl={main_data?.next_page_url} postsPerPage={main_data?.per_page} paginate={paginate} totalPosts={main_data?.total_page} hasMorePage={main_data?.has_more_page} onFirstPage={main_data?.first_page} hasPage={main_data?.has_page} currentPage={main_data?.current_page} />

            </div>
        </>
    )
}