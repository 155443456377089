import React from 'react';
import '../../../Timeline.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CountdownTimer from '../../../Props/CountdownTimer';
export default function RmsTimeline() {
    const rms_active_selector = useSelector((state) => state.rmsActiveAlarmSlice)
    return (
        <>
            <ul class="cbp_tmtimeline">
                {!rms_active_selector?.loading && rms_active_selector.data?.alarms?.map((rms_alrm, index) => (
                    <li>
                        <time class="cbp_tmtime"><span>{rms_alrm.alarm_time}</span> <span>{rms_alrm.time_ago}</span></time>
                        <div class={`cbp_tmicon ${rms_alrm.alarm_type == 1 ? 'bg-warning' : rms_alrm.alarm_type == 2 ? 'bg-warning' : rms_alrm.alarm_type == 3 ? 'bg-danger' : rms_alrm.alarm_type == 4 ? 'bg-warning' : rms_alrm.alarm_type == 5 ? 'bg-info' : rms_alrm.alarm_type == 6 ? 'bg-danger' : rms_alrm.alarm_type == 7 ? 'bg-info' : rms_alrm.alarm_type == 8 ? 'bg-info' : rms_alrm.alarm_type == 9 ? 'bg-info' : rms_alrm.alarm_type == 10 ? 'bg-danger' : rms_alrm.alarm_type == 11 ? 'bg-warning' : rms_alrm.alarm_type == 12 ? 'bg-danger' : rms_alrm.alarm_type == 13 ? 'bg-danger' : rms_alrm.alarm_type == 14 ? 'bg-info' : rms_alrm.alarm_type == 15 ? 'bg-info' : rms_alrm.alarm_type == 16 ? 'bg-info' : rms_alrm.alarm_type == 17 ? 'bg-info' : rms_alrm.alarm_type == 18 ? 'bg-info' : rms_alrm.alarm_type == 19 ? 'bg-info' : rms_alrm.alarm_type == 20 ? 'bg-info' : rms_alrm.alarm_type == 21 ? 'bg-info' : rms_alrm.alarm_type == 22 ? 'bg-info' : 'bg-danger'}`}>
                            {rms_alrm.alarm_type == 1 ?

                                <i class="fa-sharp fa-solid fa-utility-pole text-white"></i>
                                :
                                rms_alrm.alarm_type == 2 ?
                                    <i class="fa-solid fa-battery-exclamation"></i>
                                    :
                                    rms_alrm.alarm_type == 3 ?

                                        <i class="fa-thin fa-cabinet-filing"></i>

                                        :
                                        rms_alrm.alarm_type == 4 ?
                                            <i class="fa-light fa-lock"></i>
                                            :
                                            rms_alrm.alarm_type == 5 ?
                                                <i class="fa-solid fa-bullhorn"></i>
                                                :
                                                rms_alrm.alarm_type == 6 ?
                                                    <i class="fa-light fa-fire-smoke"></i>
                                                    :
                                                    rms_alrm.alarm_type == 7 ?
                                                        <i class="fa-regular fa-door-open"></i>
                                                        :
                                                        rms_alrm.alarm_type == 8 ?
                                                            <i class="fa-sharp fa-door"></i>
                                                            :
                                                            rms_alrm.alarm_type == 9 ?
                                                                <i class="fa-light fa-credit-card-blank"></i>
                                                                :
                                                                rms_alrm.alarm_type == 10 ?
                                                                    <i class="fa-regular fa-plug"></i>
                                                                    :
                                                                    rms_alrm.alarm_type == 11 ?
                                                                        <i class="fa-regular fa-plug"></i>
                                                                        :
                                                                        rms_alrm.alarm_type == 14 ?
                                                                            <i class="fa-regular fa-plug"></i>
                                                                            :
                                                                            rms_alrm.alarm_type == 15 ?
                                                                                <i class="fa-solid fa-battery-exclamation"></i>
                                                                                :
                                                                                rms_alrm.alarm_type == 29 ?
                                                                                    <i class="fa-solid fa-temperature-high"></i>
                                                                                    :

                                                                                    <i class="fa-sharp fa-solid fa-bell"></i>
                            }

                        </div>
                        <div class="cbp_tmlabel border-warning bg-timeline">

                            <div className='row'>


                                <div className='col-8'>
                                    <h2><a href="javascript:void(0);" className='text-color-yellow'><Link to={`site/${rms_alrm.site_id}`}>{rms_alrm.site_id}</Link></a></h2>
                                    <p className='mb-0 li-1st'><b className='mb-0'>{rms_alrm.name}</b></p>
                                    <p className='mb-0 li-2st'>{rms_alrm.alarm_date}</p>
                                </div>
                                <div className='col-4'>
                                    <p className='mb-0 li-2st'>
                                        {rms_alrm.alarm_end != null ?
                                            <span className="elisp_time">{rms_alrm.total_elaspsed}</span>
                                            :
                                            <CountdownTimer targetDate={rms_alrm.start} />
                                        }
                                    </p>

                                    <div className='mt-2'>
                                        <div className="dropdown">
                                            <button className="btn btn-light btn-sm dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="fa-light fa-ellipsis" />
                                            </button>
                                            <ul className="dropdown-menu dropdown-menu-end border-0 shadow-sm fs-14" style={{}}>
                                                <li>
                                                    <a href='#' className="dropdown-item">
                                                        <i className="fa-light fa-eye me-2 text-muted" /> Acknowledgement
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>
                    </li>
                ))}

            </ul>
        </>
    );
}