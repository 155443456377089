import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { Login } from "./Auth/Login";
import Home from "./Page/Home";
import RMSMonitor from "./Page/RMSMonitor";
import Footer from "./Partials/Footer";
import Nav from "./Partials/Nav";
import SiteCodeMenu from "./Partials/SiteCodeMenu";
import { USER_LOGGED_IN } from "./Reducer/authSlice";
import RealtimeAlarm from "./Page/RealtimeAlarm";
import Alarm from "./Page/Alarm";
import RmsAlarm from "./Component/RmsAlarm";
import DoorOpen from "./Component/Door/DoorOpen";

import LockOpen from "./Component/Door/LockOpen";
import AllLock from "./Component/Lock/AllLock";
import AllSite from "./Page/AllSite";
import { Toaster } from "react-hot-toast";
import SiteDashboard from "./Page/dashboard/SiteDashboard";
import SiteBody from "./Page/dashboard/SiteBody";
import DcEnergyMeter from "./Page/dashboard/dc_energy/DcEnergyMeter";
import DcGraph from "./Page/dashboard/dc_energy/grpah/dc_graph";
import Overload from "./Page/dashboard/dc_energy/overload/Overload";
import LoadDisconnect from "./Page/dashboard/dc_energy/load_disconnect/LoadDisconnect";
import DcTenant from "./Page/dashboard/dc_energy/dc_tenant/DcTenant";
import DcConsumption from "./Page/dashboard/dc_energy/dc_consumption/DcConsumption";
import SiteAlarm from "./Page/dashboard/alarm/SiteAlarm";
import OnlineSite from "./Page/online_site/OnlineSite";
import OfflineSite from "./Page/offline_site/OfflineSite";
import RmsSiteAlarm from "./Page/dashboard/alarm/rms/RmsSiteAlarm";
import AcEnergyMeter from "./Page/dashboard/ac_energy/AcEnergyMeter";
import SiteAsset from "./Page/dashboard/asset/SiteAsset";
import SiteBatteryHome from "./Page/dashboard/battery/SiteBatteryHome";
import CellHome from "./Page/dashboard/battery/app/CellHome";
import Realtime from "./Notification/Realtime";
import CellMonitoring from "./Page/dashboard/battery/app/CellMonitoring";
import BatteryGraph from "./Page/dashboard/battery/app/BatteryGraph";
import PgHome from "./Page/dashboard/pg/PgHome";
import DcEnergy from "./Page/dc_energy/DcEnergy";
import RectifierIndex from "./Page/rectifier/RectifierIndex";
import RmsStation from "./Page/rms_station/RmsStation";
import AllDoor from "./Page/door/AllDoor";
import AcmGraph from "./Page/dashboard/ac_energy/AcmGraph";
import SiteRectifier from "./Page/dashboard/reactifier/SiteRectifier";
import SiteRectifierReport from "./Page/dashboard/reactifier/SiteRectifierReport";
import SiteRectifierGraph from "./Page/dashboard/reactifier/SiteRectifierGraph";
import OfflineDoor from "./Page/offline_site/OfflineDoor";
import JobIndex from "./Page/job/JobIndex";
import VendorIndex from "./Page/vendor/VendorIndex";
import VendorUserIndex from "./Page/vendor_user/VendorUserIndex";
import ReportIndex from "./Page/report/ReportIndex";

function App() {
  const { user, accessToken } = useSelector((state) => state.authReducer);
  const selector_offlineID = useSelector((state) => state.offlineIDReducer)

  const location = useLocation();
  const dispatch = useDispatch();
 
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("auth"));
    if (userInfo) {
      dispatch(USER_LOGGED_IN(userInfo));
    }
   
  }, [dispatch]);

  // !user ||
  if (!accessToken) {
    return (
      <>
        <Login />
      </>
    );
  }

  return (
    <>
    <Realtime/>
      {location.pathname == '/reports' || location.pathname == '/organisation_users' || location.pathname == '/organisations' || location.pathname=='/rms_stations' || location.pathname=='/all_jobs' || location.pathname=='/all_doors' || location.pathname=='/rectifier_monitoring' || location.pathname === '/' || location.pathname == '/timeline' || location.pathname == '/alarms' || location.pathname == '/rms_alarms' || location.pathname == '/all_sites'|| location.pathname=='/offline_sites' || location.pathname == '/offline_sites/'+selector_offlineID.data || location.pathname=='/online_sites' || location.pathname=='/dc_energy'? '' : <SiteCodeMenu />}

      <div className={`page ${location.pathname == '/reports' || location.pathname == '/organisation_users' || location.pathname == '/organisations' || location.pathname=='/rms_stations' || location.pathname=='/all_jobs'  || location.pathname=='/all_doors' ||location.pathname === '/' || location.pathname=='/rectifier_monitoring' || location.pathname == '/timeline' || location.pathname == '/alarms' || location.pathname == '/rms_alarms' || location.pathname == '/all_sites' || location.pathname=='/offline_sites' || location.pathname == '/offline_sites/'+selector_offlineID.data || location.pathname=='/online_sites'|| location.pathname=='/dc_energy'? 'left-home' : ''}`}>
        <Nav />
        <div className="section-body">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/monitoring" element={<RMSMonitor />} />
            <Route path="/online_sites" element={<OnlineSite />} />
            <Route path="/offline_sites" element={<OfflineSite />} />
            <Route path="/offline_sites/:id" element={<OfflineDoor />} />
            <Route path="/timeline" element={<RealtimeAlarm />} />
            <Route path="/alarms" element={<Alarm />} />
            <Route path="/rms_alarms" element={<RmsAlarm />} />
            <Route path="/door_management/all_door" element={<AllDoor />} />
            <Route path="/door_management/door_open" element={<DoorOpen />} />
            <Route path="/door_management/lock_open" element={<LockOpen />} />
            <Route path="/door_management/lock_closed_failed" element={<LockOpen />} />
            <Route path="/door_management/lock_fault" element={<LockOpen />} />
            <Route path="/door_management/reader_fault" element={<LockOpen />} />
            <Route path="/door_management/security_breach" element={<LockOpen />} />
            <Route path="/door_management/vibration" element={<LockOpen />} />
            <Route path="/door_management/buzzer" element={<LockOpen />} />
            <Route path="/door_management/card_temper_alert" element={<LockOpen />} />
            <Route path="/rms_stations" element={<RmsStation />} />
            <Route path="/all_doors" element={<AllDoor />} />
            <Route path="/all_sites" element={<AllSite />} />
            <Route path="/reports" element={<ReportIndex />} />

            <Route path="/site/:id" element={<SiteDashboard />}/>
            <Route path="/site/:id/dc_energy_meter" element={<DcEnergyMeter />}/>
            <Route path="/site/:id/dc_energy_meter/graph" element={<DcGraph />}/>
            <Route path="/site/:id/dc_energy_meter/overload" element={<Overload />}/>
            <Route path="/site/:id/dc_energy_meter/load_disconnect" element={<LoadDisconnect />}/>
            <Route path="/site/:id/dc_energy_meter/dc_tenant" element={<DcTenant />}/>
            <Route path="/site/:id/dc_energy_meter/dc_consumption" element={<DcConsumption />}/>

            <Route path="/site/:id/alarms" element={<SiteAlarm />}/>
            <Route path="/site/:id/alarms/rms" element={<RmsSiteAlarm />}/>
            <Route path="/site/:id/ac_energy_meter/:acm_id" element={<AcEnergyMeter />}/>
            <Route path="/site/:id/ac_energy_meter/:acm_id/graph" element={<AcmGraph />}/>
            <Route path="/site/:id/assets" element={<SiteAsset />}/>
            <Route path="/site/:id/bms" element={<SiteBatteryHome />}/>
            <Route path="/site/:id/bms/:sl/dashboard" element={<CellHome />}/>
            <Route path="/site/:id/bms/:sl/cell_monitoring" element={<CellMonitoring />}/>
            <Route path="/site/:id/bms/:sl/graph" element={<BatteryGraph />}/>
            <Route path="/site/:id/pg" element={<PgHome />}/>
            <Route path="/site/:id/rectifier/:rid" element={<SiteRectifier />}/>
            <Route path="/site/:id/rectifier/:rid/report" element={<SiteRectifierReport />}/>
            <Route path="/site/:id/rectifier/:rid/graph" element={<SiteRectifierGraph />}/>
            <Route path="/dc_energy" element={<DcEnergy />}/>
            <Route path="/rectifier_monitoring" element={<RectifierIndex />}/>
{/*             
            <Route path="/site/:id/assets" element={<DcEnergyMeter />}/>
            <Route path="/site/:id/solar/:sl_no" element={<DcEnergyMeter />}/>
            <Route path="/site/:id/rectifer/:sl_no" element={<DcEnergyMeter />}/>
            <Route path="/site/:id/pg_status" element={<DcEnergyMeter />}/>
            <Route path="/site/:id/map" element={<DcEnergyMeter />}/> */}
            <Route path="/all_jobs" element={<JobIndex />}/>
            <Route path="/organisations" element={<VendorIndex />}/>
            <Route path="/organisation_users" element={<VendorUserIndex />}/>
          </Routes>

        </div>
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
        <Footer />
      </div>

    </>

  );
}

export default App;
