import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../Network/API";
import { SITE_CODE_FETCH_SUCCESS } from "../Reducer/siteCodeSlice";
import { Link, useNavigate } from "react-router-dom";

export default function SiteCodeMenu(){
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true)
    API.get('/v1/oauth/all_sites').then((response) => {
      dispatch(SITE_CODE_FETCH_SUCCESS(response.data.data.sites))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
    });
  }, []);

  
  const search = (site_code) => {
    API.get('/v1/oauth/all_sites?site_id='+site_code).then((response) => {
      dispatch(SITE_CODE_FETCH_SUCCESS(response.data.data.sites))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
    });
  }
  const selector = useSelector((state) => state.siteCodeReducer)
  const selectorSite = useSelector((state) => state.siteIdReducer)
  const sites=selector?.data

    return (
        <>
          <div className="main_content">
          <div id="left-sidebar" className="sidebar ">
            <form className="app-search">
              <div className="position-relative">
                <input type="text" onChange={(e)=>search(e.target.value)} className="form-control" placeholder="Search Site ID......." autoComplete="off" id="search-options"  />
                <span className="fa-light fa-magnifying-glass search-widget-icon" />
              </div>
            </form>
            <ul className="list-unstyled ps-0 left-sidebar-menu">
            {!selector?.loading &&  sites.length>0 && sites?.map((site, index) => (
              <li className key={index}>
                <button onClick={()=>navigate(`site/${site.site_id}`)} className={`border-0 border-bottom border-light left-sidebar-font-color btn btn-toggle d-inline-flex align-items-center collapsed w-100 rounded-0 ${selectorSite?.data==site.site_id?'collapsed':''}`} data-bs-toggle="collapse" data-bs-target={`#home-collapse${site.site_id}`} aria-expanded="true">
                  {site.site_id}
                </button>
                <div className={`collapse ${selectorSite?.data==site.site_id?'show':''}`} id={`home-collapse${site.site_id}`}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><Link to={`/site/${site.site_id}/alarms`} className="d-inline-flex text-decoration-none "><i className="fa-duotone fa-angle-right me-2" />Alarm History</Link></li>
                    <li><Link to={`/site/${site.site_id}/assets`} className="d-inline-flex text-decoration-none "><i className="fa-duotone fa-angle-right me-2" />Asset History</Link></li>
                    {site.acm.length>0 && site.acm?.map((acm, i) => (
                    <li><Link key={i} to={`/site/${site.site_id}/ac_energy_meter/${acm.acm_id}`} className="d-inline-flex text-decoration-none "><i className="fa-duotone fa-angle-right me-2" />AC_Meter_{i+1}</Link></li>
                  ))}
                    <li><Link to={`/site/${site.site_id}/dc_energy_meter`} className="d-inline-flex text-decoration-none "><i className="fa-duotone fa-angle-right me-2" />DC Energy Meter</Link></li>
                    {site.total_battery>0?
                    <li><Link to={`/site/${site.site_id}/bms`} className="d-inline-flex text-decoration-none "><i className="fa-duotone fa-angle-right me-2" />Battery({site.total_battery})</Link></li>
                  :''}
                  
                    {site.rectifier?.map((rectif, i) => (
                    <li><Link key={i} to={`/site/${site.site_id}/rectifier/${rectif.rectifier_sl_no}`} className="d-inline-flex text-decoration-none "><i className="fa-duotone fa-angle-right me-2" />{rectif.rectifier_name}</Link></li>
                    ))}

                    {/* {site.solars?.map((solar, i) => (
                    <li><a key={i} href="#" className="d-inline-flex text-decoration-none "><i className="fa-duotone fa-angle-right me-2" />{solar.name}</a></li>
                    ))} */}

                    {/* <li><Link to={`/site/${site.site_id}/pg`} className="d-inline-flex text-decoration-none "><i className="fa-duotone fa-angle-right me-2" />PG Monitoring</Link></li> */}
                    
                    {/* <li><a href="#" className="d-inline-flex text-decoration-none "><i className="fa-duotone fa-angle-right me-2" />GPS MAP</a></li> */}
                  </ul>
                </div>
              </li>
             ))}
            </ul>
          </div>
        </div>
        </>
    )
}