import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../login.css';
import API from "../Network/API"
import { USER_LOGGED_IN } from '../Reducer/authSlice';
import toast from 'react-hot-toast';

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    if (loaderElement && !loaderElement.hasAttribute('hidden')) {
      loaderElement.setAttribute('hidden', 'true');
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    if (typeof email !== "undefined") {
      if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/)) {
        toast.error('Email is not correct!');
        return false;
      }
    }
    var data = {
      'email': email,
      'password': password,
    }
    API.post('/v1/oauth/token', data).then(response => {
      setLoading(false);
      if (response.data.success === true) {
        localStorage.setItem(
          'auth',
          JSON.stringify({
            user: response?.data?.data?.user,
            accessToken: response?.data?.data?.token,
          })
        )
        localStorage.setItem("token",response?.data?.data?.token)
        window.location.href = '/';
        // dispatch(
        //   USER_LOGGED_IN({
        //     user: response?.data?.data?.user,
        //     accessToken: response?.data?.data?.token,
        //   })
        // )
        // window.location.href = '/';
        toast.success(response.message, { duration: 3000 })
      } else {

      }
    }).catch((error) => {
      setLoading(false);
      console.log(error)
      // toast.error("error.response.data.message")
    });

  }
  return (
    <>
      <div className="login-page">
        <div className="login-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 login-page-7-hide">
                <div className="login-header-img-content">
                  <a className="navbar-brand" href="#">
                    {/* <img src="/img/logo.svg" height={50} className="d-inline-block align-top" alt="" /> */}
                    <img src="/img/summit_logo.png" height={80} className="d-inline-block align-top" alt="" />
                  </a>
                  <h6 className="font-weight-bold">"We offer smart solutions that  raise your business above the expected"</h6>
                </div>
                <div className="login-page-image">
                  {/* <img className="main-img-login" style={{height:'calc(100vh - 135px)'}} src="/img/telecomunication-tower.svg" alt="" /> */}
                  <img className="main-img-login" style={{height:'calc(100vh - 165px)'}} src="/img/telecomunication-tower.svg" alt="" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="login-heading">
                  <div className="imack-logo-area">
                    <img src="/img/imac-logo-black.png" className="imack-logo" alt="" />
                  </div>
                  <div className="login-box mt-3">
                    <div className="login-area mt-5">
                      <form onSubmit={handleSubmit}>

                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1 " className="text-white">Email</label>
                          <div className="position-relative">
                            <input onChange={(e) => setEmail(e.target.value)} name="email" type="email" className="form-control bg-light position-relative custom-email " id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" defaultValue="" required />
                            <div className="login-input-icon position-absolute">
                              <i className="fa-solid fa-user" />
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1 " className="text-white">Password</label>
                          <div className="position-relative">
                            <input onChange={(e) => setPassword(e.target.value)} type="password" name="password" className="form-control bg-light custom-password " id="exampleInputPassword1" placeholder="Enter password" required />
                            <div className="login-input-icon position-absolute">
                              <i className="fa-solid fa-key" />
                            </div>
                          </div>
                        </div>
                        <div className="form-check mb-3 d-flex justify-content-between">
                          <div className="keep-login">
                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                            <label className="form-check-label text-white" htmlFor="exampleCheck1">keep me logged
                              in</label>
                          </div>
                          <a href="#" className="text-white">Forgot password?</a>
                        </div>
                        <button type="submit" className="btn btn-edotco"><i className="fa-solid fa-paper-plane mr-1" /> SignOn {isLoading ? (
                                                    <span
                                                        className="spinner-border spinner-border-sm ml-5"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : (
                                                    <span></span>
                                                )}</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="login-footer">
          <div className="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="footer-left d-flex align-items-center">
                    <p className="m-0 mr-2">Powered by</p>
                    <img src="/img/ajra-logo.svg" alt="" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer-right d-flex justify-content-end align-items-center">
                    <p className="m-0 mr-2">Available on</p>
                    <img className="mr-2" src="/img/playstor.svg" alt="" />
                    <img src="/img/app-stor.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}