import { Link } from "react-router-dom";
import CountdownTimer from "../../Props/CountdownTimer";

export default function LockAlarmCard({ data }) {
  return (
    <>
      <table className={`table table-borderless alarm_table bg-white  mt-1 ${data.alarm_type==1?'border-warning':data.alarm_type==2?'border-warning':data.alarm_type==3?'border-danger':data.alarm_type==4?'border-warning':data.alarm_type==5?'border-info':'border-danger'}`} id="alart_list_table" style={{ marginBottom: '0px', width: '97%' }}>
        <tbody><tr>
          <td className="status">
            <label className="label text-uppercase" style={{ fontWeight: 'bold', fontSize: '13px' }}><Link style={{color: '#00aced'}} to={`/site/${data.site_id}`}>{data.site_id}</Link></label>
          </td>
          <td className="font-weight-bold" valign="top" style={{ verticalAlign: 'top' }}>Elapsed Time</td>
          <td width="20%" valign="top" className="" style={{ verticalAlign: 'top' }}>
            <label className={`alarm_type_name badge ${data.alarm_type == 6 ? 'bg-danger' : data.alarm_type == 11 ? 'bg-success' : 'bg-warning text-dark'} `}>
              {data.name}
            </label>
          </td>
        </tr>
          <tr className="time_tr" style={{ borderTop: '1px solid #f3f1f1' }}>
            <td className="status" rel={data.alarm_type} data-value={data.site_id}>
              {data.alarm_start}
              <>
                {data.alarm_end != null ?
                  <p>{data.alarm_end}</p> : ''
                }
              </>
            </td>
            <td className="font-weight-bold">
              {data.alarm_end != null ?
               <span className="elisp_time">{data.total_elaspsed}</span> 
                :
                <CountdownTimer targetDate={data.start} />
              }
            </td>
            <td width="20%" style={{ textAlign: 'left' }}>
              {data.alarm_type == 1 &&  data.alarm_end==null?
                <button type="button" id="alarm_clear" className="btn btn-sm btn-info">Clear</button>
                : ''}
            </td>
          </tr>
          {data.alarm_type == 7 ?
            <tr>
              <td>
                {
                  data.command_type == 1 ?
                    <>
                      <strong>Door Open By:</strong><br />
                      <strong>{data.command_details?.name}</strong>
                      <br /> <b>Org:</b> <span>{data.command_details?.organization}</span>
                      <br /> <b>Mobile:</b> <span>{data.command_details?.phone}</span>
                      <br /> <b>Job Details:</b> <span>{data.command_details?.details}</span>
                    </>
                    :
                    <>
                      <strong>Door Open By:</strong><br />
                      <strong>{data.user?.name}</strong>
                      <br /> <b>Org:</b> <span>{data.user?.vendor_name}</span>
                      <br /> <b>Mobile:</b> <span>{data.user?.phone}</span>
                      <br /> <b>Card:</b> <span>{data.user?.card_number}</span>
                      <br /> <b>Job Details:</b> <span>{data.user?.job_name}</span>
                    </>
                }
              </td>
              <td>
                {
                  data.command_type == 1 ?
                    <>
                      <strong>Lock Command By:</strong><br />
                      <strong>{data.user?.name}</strong>
                      <br /> <b>Org:</b> <span>{data.user?.vendor_name}</span>
                      <br /> <b>Mobile:</b> <span>{data.user?.phone}</span>
                    </> : ''
                }
              </td>
            </tr>
            : ''}
        </tbody>
      </table>
    </>
  )
}