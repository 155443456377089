import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DOOR_FETCH_ERROR, DOOR_FETCH_SUCCESS } from "../../Reducer/DoorSlice";
import API from "../../Network/API";
import { useEffect } from "react";
import CountdownTimer from "../../Props/CountdownTimer";
import Paginate from "../../CustomHook/Paginate";
import { Link } from "react-router-dom";
import SiteFilterModel from "../../Models/SiteFilterModel";
import UnlockDoor from "../../Models/UnlockDoor";
import { LOCK_ALARM_FETCH_SUCCESS } from "../../Reducer/LockAlarmListSlice";

export default function AllDoor() {
    const [nxtPage, setNextPage] = useState(null);
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.doorReducer)
    const selector_status = useSelector((state) => state.doorStatusReducer)
    const [modalDialog, setModalDialog] = useState(false);
    const [modalDialogUlock, setModalDialogUlock] = useState(false);
    const [siteId, setSiteID] = useState();
    const [isLoading, setLoading] = useState(false);
    const unlockDoorButton = (value) => {
        setSiteID(value)
        setModalDialogUlock(!modalDialogUlock)
    }

    useEffect(() => {
        all_door()
        API.get('/v1/oauth/lock_alarm_nav').then((response) => {
            dispatch(LOCK_ALARM_FETCH_SUCCESS(response.data.data))
          }).catch((error) => {
          });
      }, []);

    const all_door = () => {
        setLoading(true)
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/all_door').then((response) => {
          if (loaderElement && !loaderElement.hasAttribute('hidden')) {
            loaderElement.setAttribute('hidden', 'true');
          }
          dispatch(DOOR_FETCH_SUCCESS(response.data.data))
          setLoading(false)
        }).catch((error) => {
          setLoading(false)
        });
        
    }

    const toggleModal = () => {
        setModalDialog(!modalDialog)
    }

    const paginate = (pageNumber) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/all_door?page=' + pageNumber).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
        });
    };

    useEffect(() => {
        if (nxtPage != null) {
            const loaderElement = document.querySelector(".loading-mask");
            loaderElement.removeAttribute('hidden');
            loaderElement.setAttribute('visible', 'true');
            API.get(nxtPage).then((response) => {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
                dispatch(DOOR_FETCH_SUCCESS(response.data.data))

            }).catch((error) => {
                dispatch(DOOR_FETCH_ERROR(error))
            });
        }

    }, [nxtPage]);

    const previousPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const nextPage = (nxtPage) => {
        setNextPage(nxtPage)
    }

    const searchSite = (site_code) => {
        API.get('/v1/oauth/all_door?site_id=' + site_code).then((response) => {
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
        });
    }


    const syncData = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/all_door').then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(DOOR_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            dispatch(DOOR_FETCH_ERROR(error))
        });
    }


    const selector_lock = useSelector((state) => state.lockAlarmListReducer)

    const strAscending = [...selector?.main_data].sort((a, b) =>
    a.site_id > b.site_id ? 1 : -1,
  );
    return (
        <>
         
                <div className="row text-center  mb-2 mt-2 me-1 ms-1">


                    <div className="d-flex justify-content-end">
                        <div class="input-group input-group-sm max-with-searbar">
                            <input type="text" onChange={(e) => searchSite(e.target.value)} class="form-control" placeholder="search by site id...." />
                            <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>

                        </div>
                        <button class="btn btn-warning  ms-2" type="button" onClick={() => syncData()}><i class="fa-light fa-refresh me-1" />Sync</button>

                        <div class="dropdown custom-dropdown-grop-btn ms-2">
                            <button class="btn btn-outline-info text-dark bg-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="fa-duotone fa-bell"></i> ACS Event ({selector_lock?.data?.total_alarm > 0 ? <span className="badge bg-danger">{selector_lock?.data?.total_alarm}</span> : 0})
                            </button>
                            <ul class="dropdown-menu">
                                {!selector_lock?.loading && selector_lock?.data?.lock_alarms?.map((lock_alarm, index) => (
                                    <li>

                                        <Link className="dropdown-item d-flex gap-2 align-items-center justify-content-between" to={`/alarms?type=${lock_alarm.order_list}`}>
                                            {lock_alarm.name}
                                            <div className="form-check form-switch mb-0">
                                                {lock_alarm.value > 0 ? <span className="badge bg-danger">{lock_alarm.value}</span> : <span className="badge bg-dark">0</span>}
                                            </div>

                                        </Link>

                                    </li>
                                ))}
                            </ul>
                        </div>


                    </div>

                </div>
       

            <div className="table-responsive">

                <table className="table table-bordered table-striped m-0 table-sm bg-white text-center">
                    <thead className="text-uppercase">
                        <tr>
                            <th className="toi-primary">Last Updated</th>
                            <th className="toi-primary">CLUSTER</th>
                            <th className="toi-primary">Zone</th>
                            <th className="text-center toi-primary" width={`5%`}>Site ID</th>
                            <th className="text-center toi-primary" width={`5%`}>easi Site ID</th>
                            <th className="text-center toi-primary">Network</th>
                            <th className="text-center toi-primary">ELAPSED TIME</th>
                            <th className="text-center toi-primary">Door</th>
                            <th className="text-center toi-primary">Lock</th>
                            <th className="text-center toi-primary">RFID</th>
                            <th className="text-center toi-primary">Security Breach</th>
                            <th className="text-center toi-primary">Vibration</th>
                            <th className="text-center toi-primary">Buzzer</th>
                            <th className="text-center toi-primary">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!selector?.loading && strAscending?.map((site, index) => (
                            <tr className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`}>
                                <td className="update_time">
                                    {site.update_time}
                                </td>
                                <td>{site?.cluster?.name}</td>
                                <td>{site?.zone?.name}</td>

                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.site_id}</Link>
                                </td>
                                <td className="text-center">
                                    <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.customer_site_id}</Link>
                                </td>

                                <td className="text-center">
                                    {site.device_live_status === 1 ?
                                        <div className="signal-bars mt1 sizing-box good four-bars">
                                            <span>
                                                <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                                            </span>
                                            <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                                            </div>
                                            <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                                            </div>
                                        </div>
                                        :
                                        <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                                    }
                                </td>
                              
                                <td>
                                {site.door_status == 1?
                                <CountdownTimer targetDate={site.door_open_time} />
                                :
                                <span className="elisp_time">00:00:00</span>

                                }
                                </td>
                          
                                <td className="text-center">
                                    {site.door_status == 1 && site.breach_status == 0 ?

                                        <img src="/assets/icon/door-yello.png" alt="" className="img-size" title="Door Open" />
                                        :
                                        site.breach_status == 1 ?
                                            <img src="/assets/icon/door-red.png" alt="" className="img-size" title="Security Breach" />
                                            :
                                            site.door_open_timeout == 1 && site.breach_status == 0 ?
                                                <img src="/assets/icon/door-red.png" alt="" className="img-size" title="Door Open Timeout" />
                                                :
                                                <img src="/assets/icon/door-green.png" alt="" className="img-size" title="Door Closed" />

                                    }
                                </td>
                                <td className="text-center">

                                    {site.lock_fault == 0 && site.lock_open_status == 1 ?

                                        <img src="/assets/icon/lock-yello.png" alt="" className="img-size" title="Lock Open" />
                                        :
                                        site.lock_fault == 1 ?
                                            <img src="/assets/icon/lock-red.png" alt="" className="img-size" title="Lock Fault" />
                                            :
                                            site.lock_close_fail == 1 ?
                                                <img src="/assets/icon/lock-red.png" alt="" className="img-size" title="Lock Close Failed" />
                                                :
                                                <img src="/assets/icon/lock-green.png" alt="" className="img-size" title="Lock Closed" />

                                    }
                                </td>
                                <td className="text-center">
                                    {site.card_reader_fault == 0 && site.card_temper_alart == 1 ?

                                        <img src="/assets/icon/lock-yello.png" alt="" className="img-size" title="Card Temper Alert" />
                                        :
                                        site.card_reader_fault == 1 && site.card_temper_alart == 0 ?
                                            <img src="/assets/icon/lock-red.png" alt="" className="img-size" title="Card Reader Fault" />
                                            :
                                            <img src="/assets/icon/rfid-green.png" alt="" className="img-size" title="" />

                                    }

                                </td>
                                <td className="text-center">
                                    {site.breach_status == 1 ?
                                        <img src="/assets/icon/sb_red.png" alt="" className="img-size" title="Security Breach" />
                                        :
                                        <img src="/assets/icon/sb_green.png" alt="" className="img-size" title="Normal" />
                                    }
                                </td>
                                <td className="text-center">
                                    {site.vib_sens_status == 1 ?
                                        <i class="fa-solid fa-sensor-on text-red" style={{ fontSize: '18px' }} />
                                        :
                                        <i class="fa-solid fa-sensor-on text-success" style={{ fontSize: '18px' }} />
                                    }

                                </td>
                                <td className="text-center">
                                
                                    {site.buz_status == 1 ?
                                        <i class="fa-duotone fa-bullhorn text-red" style={{ fontSize: '18px' }} />
                                        :
                                        <i class="fa-duotone fa-bullhorn text-success" style={{ fontSize: '18px' }} />
                                    }

                                </td>
                                <td className="text-center" width={'10%'}>
                                    <div className="btn-group btn-group-sm">
                                        {site?.lock_open_status == 1 || site.lock_command == 1 || site.device_live_status==0 ?
                                            <button disabled type="button" className="btn btn-secondary waves-effect" id="btn1">
                                                <i className="fa fa-key mr-1" /> <span>Unlock</span></button>
                                            :
                                            <button type="button" className="btn btn-secondary waves-effect" id="btn1" onClick={() => unlockDoorButton(site.id)}>
                                                <i className="fa fa-key mr-1" /> <span>Unlock</span></button>

                                        }
                                        <button type="button" className="btn btn-sm btn-info dropdown-toggle waves-effect" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fe-more-vertical font-18 align-middle" />
                                        </button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" href="#"><i className="fe-plus mr-1" /> Create Job</a>
                                            <a className="dropdown-item" href="#"><i className="fe-settings mr-1" /> Configurations</a>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>

            </div>
            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />
       
            <UnlockDoor site_id={siteId} isModalVisible={modalDialogUlock} handleShowB={() => setModalDialogUlock(false)} />

        </>
    );

}