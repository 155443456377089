import { useDispatch } from "react-redux";
import DcTenantTab from "./props/DcTab";
import { useEffect, useRef } from "react";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";
import { Link, useParams } from "react-router-dom";

import API from "../../../Network/API";
import { useState } from "react";
import ReactEcharts from "echarts-for-react";
import DcTab from "./DcTab";

export default function DcEnergyMeter() {
    const [rms, setRms] = useState()
    const [dc_tanent, setDcTenant] = useState()
    const [dc_hourly, setDcHourly] = useState()
    const [dc_kw_avg_hourly, setDcAvgHourly] = useState()
    const [dc_kw_max_hourly, setDcMaxHourly] = useState()
    const [dc_cons, setDcConsumption] = useState()
    const echarts_react_1 = useRef();
    const echarts_react_2 = useRef();
    const echarts_react_3 = useRef();
    const ddd = useParams();
    const dispatch = useDispatch()
    const date = '2023-03-01'

    const [dateValue, setDateChange] = useState(new Date().toISOString().slice(0, 10))
    const [dateValueMax, onMaxDateChange] = useState(new Date().toISOString().slice(0, 10))
    const [dateCons, setDateCons] = useState(new Date().toISOString().slice(0, 10))

    const onDateChange = (value) => {
        setDateChange(value)
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/dc_avg_kw_hourly?site_id=' + ddd.id + '&date=' + value).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setDcAvgHourly(response.data)
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

    }

    const setMaxDateChange = (value) => {
        onMaxDateChange(value)

        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/dc_max_kw_hourly?site_id=' + ddd.id + '&date=' + value).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setDcMaxHourly(response.data)
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    useEffect(() => {

        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        API.get('/v1/oauth/dc_dashboard/' + ddd.id).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            // console.log(response.data.data.rms)
            setRms(response.data.data.rms)
            setDcTenant(response.data.data.dc_meter)
        }).catch((error) => {

            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });

        API.get('/v1/oauth/dc_avg_kw_hourly?site_id=' + ddd.id + '&date=' + dateValue).then((response) => {

            setDcAvgHourly(response.data)
        }).catch((error) => {

        });

        API.get('/v1/oauth/dc_max_kw_hourly?site_id=' + ddd.id + '&date=' + dateValueMax).then((response) => {
            setDcMaxHourly(response.data)
        }).catch((error) => {
        });

        API.get('/v1/oauth/dc_power_availability?site_id=' + ddd.id + '&date=' + dateCons).then((response) => {
            setDcConsumption(response.data.data)
        }).catch((error) => {
        });


    }, [ddd.id]);



    const option2 = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {

        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: dc_kw_avg_hourly?.data?.times
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} kW'
            },
            axisPointer: {
                snap: true
            }
        },

        series: dc_kw_avg_hourly?.data?.lebel
    }

    const option3 = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {

        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: dc_kw_max_hourly?.data?.times
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} kW'
            },
            axisPointer: {
                snap: true
            }
        },

        series: dc_kw_max_hourly?.data?.lebel
    }
    return (
        <>
            <div className="row">
                <DcTab id={1} site_id={ddd.id} />
            </div>
            <div className="row ms-1 me-1">
                <div className="col-md-4" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>DC Energy Meter</div>
                        <div className="card-body bg-white" style={{ height: '260px' }}>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <article class="d-flex justify-content-between">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-regular fa-car-battery"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class="stat-cards-info__num p-0 m-0">{rms?.dc_voltage} V</p>
                                            <p class="stat-cards-info__title p-0 m-0">Battery Voltage</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-12 mb-3">
                                    <article class="d-flex justify-content-between">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-regular fa-wave-pulse"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class="stat-cards-info__num p-0 m-0">{rms?.dc_current} A</p>
                                            <p class="stat-cards-info__title p-0 m-0">Total Current</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-12">
                                    <article class="d-flex justify-content-between">
                                        <div class="stat-cards-icon bg-main-color text-white">
                                            <i class="fa-sharp fa-regular fa-lightbulb"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class="stat-cards-info__num p-0 m-0">{(Number(rms?.dc_voltage)*Number(rms?.dc_current)).toFixed(2)} W</p>
                                            <p class="stat-cards-info__title p-0 m-0">Total Load</p>
                                        </div>
                                    </article>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-md-4" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-light"><i class="fa-light fa-circle-info me-1"></i>Tenant Overview</div>
                        <div className="card-body bg-white" style={{ height: '260px' }}>
                            {dc_tanent?.length > 0 && dc_tanent?.map((teant, index) => (
                                <DcTenantTab name={`${teant.tenant_name}`} load={teant.load} current={teant.total_current} borderClass={`gp-border-top`} bgColor={`bg-main-color`} />

                            ))}


                        </div>
                    </div>
                </div>


                <div className="col-md-4" >
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1">Power Availability</b>
                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group">
                                        <div class="position-relative">
                                            <input value={dateCons}
                                                autoFocus={true} onChange={(e) => setDateCons(e.target.value)} type="date" class="form-control-sm"  autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white" style={{ height: '260px' }}>


                            <div className="row">

                            <div className="col-7">

                                <div className="col-12 mb-3">
                                    <article class="d-flex justify-content-between">
                                        <div class="stat-cards-icon bg-main-color ">
                                            <i class="fa-regular fa-car-battery  text-white"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class="stat-cards-info__num p-0 m-0">{dc_cons?.battery_availability}%</p>
                                            <p class="stat-cards-info__title p-0 m-0">Battery</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-12 mb-3">
                                    <article class="d-flex justify-content-between">
                                        <div class="stat-cards-icon bg-main-color ">
                                            <i class="fa-regular fa-wave-pulse  text-white"></i>
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class="stat-cards-info__num p-0 m-0">{dc_cons?.grid_availability}%</p>
                                            <p class="stat-cards-info__title p-0 m-0">Grid</p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-12">
                                    <article class="d-flex justify-content-between">
                                        <div class="stat-cards-icon bg-main-color">
                                            <img src="/img/pg.png" style={{height:'40px'}} />
                                        </div>
                                        <div class="stat-cards-info">
                                            <p class="stat-cards-info__num p-0 m-0">{dc_cons?.pg_availability}%</p>
                                            <p class="stat-cards-info__title p-0 m-0">PG/DG</p>
                                        </div>
                                    </article>
                                </div>
                                </div>
                                <div className="col-3">
                                <div class="pg_on_count bg-main-color shadow-dark text-white"><i class="fa-regular">{dc_cons?.period*100}<span style={{fontSize:'12px'}}>%</span></i></div>
                          
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <div className="col-md-6">
                    <div className="card shadow-sm border-0 bg-cc mt-2" style={{ minHeight: '300px' }}>
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1">DC Power</b>
                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group">
                                        <div class="position-relative">

                                            <input value={dateValue}
                                                autoFocus={true} onChange={(e) => onDateChange(e.target.value)} type="date" class="form-control-sm" placeholder="Search..." autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                            <ReactEcharts ref={echarts_react_2} style={{ height: "260px" }} option={option2} />

                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card shadow-sm border-0 bg-cc mt-2" style={{ minHeight: '300px' }}>
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1">DC Power(Maximum)</b>
                            <div class="flex-shrink-0">
                                <div class="app-search">
                                    <div class="input-group">
                                        <div class="position-relative">
                                            <input onChange={(e) => setMaxDateChange(e.target.value)} type="date" class="form-control-sm" placeholder="Search..." autocomplete="off" value={dateValueMax} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body bg-white">
                            <ReactEcharts ref={echarts_react_3} style={{ height: "260px" }} option={option3} />

                        </div>
                    </div>
                </div>

            </div>

        </>
    )
} 