import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { SITE_ID_FETCH_SUCCESS } from "../../../Reducer/SiteIdSlice";

export default function PgHome() {
    const ddd = useParams();
    const [batteries, setBattery] = useState([])
    const [rms, setRms] = useState()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
    }, [ddd.id]);
    return (
        <>
            <div className="row me-1 ms-1">

                <div className="col-md-8">
                    <div className="card shadow-sm border-0 bg-cc mt-2">
                        <div className="card-body bg-white" >
                            <div className="row">
                                <div className="col-md-6" style={{ display: 'flex' }}>
                                    <div><img src="/img/generator.png" style={{ height: '100px' }} /></div>
                                    <div>
                                        <h3 style={{ paddingTop: '20px' }}>Generator</h3>
                                        <p style={{ color: '#323131', fontSize: '14px', fontWeight: 'bold' }}>{ddd.id}</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <p className="m-0 text-uppercase font-weight-medium text-truncate" title="Statistics">Today Run
                                        Hours</p>
                                    <h3 className="font-weight-medium my-2"><span>
                                        00:00:00
                                    </span></h3>
                                    <p className="m-0">Jun 25, 2023</p>
                                </div>
                                <div className="col-md-3">
                                    <p className="m-0 text-uppercase font-weight-medium text-truncate" title="Statistics">Monthly
                                        Run Hours</p>
                                    <h3 className="font-weight-medium my-2"><span>
                                        00:00:00
                                    </span></h3>
                                    <p className="m-0">June, 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}