import { Link } from "react-router-dom";
import CountdownTimer from "./CountdownTimer";

export default function Timeline({data}){
    return(
        <>
<div className={`card card-danger ${data.category==1?data.alarm_type==6?'border-danger ':'':data.alarm_type==1?'border-danger ':'border-warning'}`}>
            <div className="card-heading icon bg-warning text-white">
              <i className="fa-light fa-bell" />
            </div>
            <div className={`card-header ${data.category==1?data.alarm_type==6?'bg-danger text-white':'':data.alarm_type==1?'bg-danger text-white':'yellow text-white'} d-flex justify-content-between px-2`}>
              <small> <Link to={`/site/${data.site_id}`}>{data.site_id}</Link> </small>
              <small>
              {data.name}
              </small>
            </div>
            <div className="card-body px-2">
              <p className="m-0 p-0">{data.alarm_start}</p>
              <p className="m-0 p-0"><i className="fa-light fa-clock" /> 
              <CountdownTimer targetDate={data.start_time}/>
              
              </p>
              
            </div>
          </div>
        </>
    )
}