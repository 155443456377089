import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../Network/API";
import { useEffect } from "react";
import { RMS_ALARM_GROUP_FETCH_SUCCESS } from "../Reducer/RmsAlarmGroupSlice";
import { ALARM_SITE_CODE_FETCH_SUCCESS } from "../Reducer/AlarmSiteCodeSlice";
import { Link, useSearchParams } from "react-router-dom";
import Paginate from "../CustomHook/Paginate";
import { SiteCodeTRNew } from "../CustomMemo/SiteCodeTRNew";
import { AlarmNav } from "../CustomMemo/AlarmNav";
import { RMS_ALARM_HISTORY_FETCH_SUCCESS } from "../Reducer/RmsAlarmHistorySlice";
import { RMS_ALARM_TYPE_FETCH_START, RMS_ALARM_TYPE_FETCH_SUCCESS } from "../Reducer/RmsAlarmTypeSlice";
import { read, utils, writeFile } from 'xlsx';
import { toast } from "react-hot-toast";
import CountdownTimer from "../Props/CountdownTimer";
import BaseApi from "../Network/BaseApi";

export default function RmsAlarm() {
  const [nxtPageAlarm, setNextPageAlarm] = useState(null);
  const [nxtPage, setNextPage] = useState(null);
  const [alarm_type, setAlarmType] = useState([])
  const [alarm_type_data, setAlarmTypeData] = useState([])
  const [site_code, setSiteCode] = useState([])
  const [site_id, setSiteId] = useState('')
  const [start_date, setStartDate] = useState('')
  const [end_date, setEndDate] = useState('')
  const [export_data, setExportData] = useState([])
  const [loading_export, setLoadingExport] = useState(false)

  const ref_input = useRef(null);
  const inputRef = useRef(null);
  const inputStartDate = useRef(null);
  const inputEndDate = useRef(null);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const selector_lock_type = useSelector((state) => state.rmsAlarmTypeReducer);
  var chec = document.querySelector('.rms_type_' + searchParams.get('type'));



  useEffect(() => {
    const loaderElement = document.querySelector(".loading-mask");
    API.get('/v1/oauth/rms_alarm_nav').then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(RMS_ALARM_GROUP_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {

    });

    
  }, []);

  useEffect(() => {
    
    // console.log(chec.title);
    if (searchParams.get('type') > 0) {
      var checkk=document.querySelector('.rms_type_' + searchParams.get('type'));
      if (alarm_type.indexOf(parseInt(searchParams.get('type'))) === -1) {
        if(checkk){

          document.querySelector('.rms_type_' + searchParams.get('type')).click();
        }

        setAlarmType([
          ...alarm_type, parseInt(searchParams.get('type'))
        ])
        dispatch(RMS_ALARM_TYPE_FETCH_SUCCESS(parseInt(searchParams.get('type'))))
        var checs = document.querySelector('.rms_type_' + searchParams.get('type'));
        setAlarmTypeData([...alarm_type_data, {
          'id': parseInt(searchParams.get('type')),
          'name': checs?.title,
        }])
      }
    }

  }, [searchParams.get('type')]);


  // useEffect(() => {
  //   const intervalCall = setInterval(() => {
  //     console.log("dsds")
  //   }, 30000);
  //   return () => {
  //     // clean up
  //     clearInterval(intervalCall);
  //   };
  // }, []);

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    var pagea = nxtPage + '&query=' + alarm_type + '&site_code=' + site_code
    API.get(pagea).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(RMS_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))

    }).catch((error) => {
    });
  }


  // useEffect(() => {
  //   const loaderElement = document.querySelector(".loading-mask");
  //   loaderElement.removeAttribute('hidden');
  //   loaderElement.setAttribute('visible', 'true');
  //   if (nxtPageAlarm != null) {
  //     var pagea = nxtPageAlarm + '&query=' + alarm_type + '&site_code=' + site_code
  //   } else {
  //     var pagea = '/v1/oauth/rms_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code
  //   }


  //   API.get(pagea).then((response) => {
  //     if (loaderElement) {
  //       if (loaderElement && !loaderElement.hasAttribute('hidden')) {
  //         loaderElement.setAttribute('hidden', 'true');
  //       }
  //     }
  //     dispatch(RMS_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))

  //   }).catch((error) => {
  //   });

  // }, [nxtPageAlarm]);





  const alarmTypeSelect = (event) => {
    if (event.target.checked) {
      if (alarm_type.indexOf(parseInt(event.target.value)) === -1) {
        setAlarmType([
          ...alarm_type, parseInt(event.target.value)
        ])

        dispatch(RMS_ALARM_TYPE_FETCH_SUCCESS(parseInt(event.target.value)))
        setAlarmTypeData([...alarm_type_data, {
          'id': parseInt(event.target.value),
          'name': event.target.title,
        }])

        setNextPageAlarm('/v1/oauth/rms_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code)
      }

    } else {
      dispatch(RMS_ALARM_TYPE_FETCH_SUCCESS(parseInt(event.target.value)))
      removeID(parseInt(event.target.value))
      removeType(parseInt(event.target.value))

    }

  }



  useEffect(() => {
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    var pagea = '/v1/oauth/rms_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code
    API.get(pagea).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(RMS_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    });

  }, [selector_lock_type.data]);


  const removeType = (u_id) => {
    setAlarmTypeData(alarm_type_data.filter(a =>
      a.id !== u_id
    ))
    removeID(u_id)
  }

  const removeID = (url) => {
    const index = alarm_type.indexOf(url);
    if (index > -1) { // only splice array when item is found
      alarm_type.splice(index, 1); // 2nd parameter means remove one item only
    }
    setAlarmType(alarm_type)
    setNextPageAlarm('/v1/oauth/rms_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code)
  }

  const checkedState = (id) => {
    return alarm_type.find((element) => {
      return element === id;
    })

  }

  const checkedSiteId = (event) => {
    if (event.target.checked) {
      if (site_code.indexOf(event.target.value) === -1) {
        setSiteCode([
          ...site_code, event.target.value
        ])
      }
    }
    else {
      removeSiteID(event.target.value)

    }

  }

  const removeSiteID = (u_id) => {
    setSiteCode(site_code.filter(a =>
      a !== u_id
    ))
  }

  const clearFiler = () => {
    setAlarmType([]);
    setAlarmTypeData([]);
    setSiteCode([]);
    setSiteId("");
    setStartDate('');
    setEndDate('');
    inputRef.current.value = "";
    inputEndDate.current.value = "";
    inputStartDate.current.value = "";

    var textinputs = document.querySelectorAll('input:checked');
    var empty = [].filter.call(textinputs, function (el) {
      el.checked = false;
    });
    // console.log(textinputs);
    // const checkBox = document.querySelector(".form-check-input");
    // console.log(checkBox);
    // window.location.replace('/rms_alarms')
    window.history.replaceState(null, '', '/rms_alarms');

    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    API.get('/v1/oauth/rms_alarm_history?page=1').then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(RMS_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    });



  }

  const refreshFiler = () => {
    
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');

    API.get('/v1/oauth/rms_alarm_history?page=1&query=' + alarm_type + '&site_id=' + site_id).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(RMS_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    });
    
  }

  const searchSite = (site_id) => {
    setSiteId(site_id);
    const loaderElement = document.querySelector(".loading-mask");
    // loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    var pagea = '/v1/oauth/rms_alarm_history?page=1&query=' + alarm_type + '&site_id=' + site_id

    API.get(pagea).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(RMS_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    });
  }

  const searchFiler = () => {
    if(start_date=='' && end_date==''){
      toast.error('Please select date rane')
      setLoadingExport(false)
      return false;
    }
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    var pagea = '/v1/oauth/rms_alarm_history?page=1&query=' + alarm_type + '&site_id=' + site_id + '&start_date=' + start_date + '&end_date=' + end_date

    API.get(pagea).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(RMS_ALARM_HISTORY_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {
    });
  }

  const handleExport = () => {
    setLoadingExport(true)
    if(start_date=='' && end_date==''){
      toast.error('Please select date rane')
      setLoadingExport(false)
      return false;
    }
    API.get('/v1/oauth/rms_alarm_export?query=' + alarm_type + '&site_id=' + site_id + '&start_date=' + start_date + '&end_date=' + end_date).then((response) => {
      const headings = [[
        'Site ID',
        'Tenant Name',
        'Alarm Source',
        'Alarm Name',
        'Start Time',
        'End Time',
        'Elasped Time'
      ]];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 40 }];
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, response.data.data.alarm_data, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, 'RMS Alarm Report.xlsx');
      setLoadingExport(false);
    }).catch((error) => {
      setLoadingExport(false)
    });


  }

  const save_download=()=>{
    setLoadingExport(true)
    if(start_date=='' && end_date==''){
      toast.error('Please select date rane')
      setLoadingExport(false)
      return false;
    }

    fetch(BaseApi()+'/v1/oauth/export_rms_alarm?query=' + alarm_type + '&site_id=' + site_id + '&start_date=' + start_date + '&end_date=' + end_date,{
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
            'Authorization':'Bearer '+localStorage.getItem("token")??""
        }
    })
    .then(response => response.blob())
    .then(blob => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'rms_alarm.csv';
      downloadLink.click();
      setLoadingExport(false)
    }).catch((error) => {
      // console.error('Error:', error);
      setLoadingExport(false)
    });
  }

  const selector_rms_alarm_nav = useSelector((state) => state.rmsAlarmGroupReducer)
  const selector_rms_alarms = useSelector((state) => state.rmsAlarmHistoryReducer)
  return (
    <>
      <div className="alarm-tab d-flex justify-content-between">
      <ul className="dc_nav"  style={{ marginTop: '-8px' }}>
      <li className="cus-tab "> <Link to={`/alarms`} className="link-active"><i className="fa-solid fa-chart-line me-1" /> ACS Alarm Events</Link></li>
      <li className="cus-tab active"> <Link to={`/rms_alarms`}><i className="fa-solid fa-chart-gantt me-1" /> RMS Alarm Events</Link></li>
          </ul>
        <div style={{ marginTop: '-8px' }}>
          <div class="input-group input-group-sm">
            <input ref={inputRef} onChange={(e) => searchSite(e.target.value)} type="text" class="form-control" placeholder="search by site id...." defaultValue={site_id} />
            <input ref={inputStartDate} onChange={(e) => setStartDate(e.target.value)} type="date" class="form-control" placeholder="" defaultValue={start_date} />
            <input ref={inputEndDate} onChange={(e) => setEndDate(e.target.value)} type="date" class="form-control" placeholder="" defaultValue={end_date} />
            <button onClick={() => searchFiler()} class="btn btn-info btn-search" type="button"><i class="fa-solid fa-search" /> Search</button>
            <button class="btn btn-success" type="button" onClick={() => save_download()}> <i class="fa-duotone fa-download" /> Export {loading_export ? (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <span></span>
            )}</button>
            <button class="btn btn-warning" type="button" onClick={() => refreshFiler()}><i class="fa-light fa-refresh" /> Refresh</button>
            <button class="btn btn-danger" type="button" onClick={() => clearFiler()}><i class="fa-light fa-remove" /> Clear</button>

          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-md-2">
          <div className="scroll-side-nav">
            {!selector_rms_alarm_nav?.loading && selector_rms_alarm_nav?.data?.rms_alarm_group?.map((rms_alarm, index) => (
              <>
                <ul className="list-group alarm-list mb-2 ">
                  <li className=" list-group-item d-flex justify-content-between align-items-center align-items-center title">
                    <h6 className="m-0 p-0">{rms_alarm.name}</h6>
                  </li>
                  {rms_alarm?.alarm?.map((alarm, index) => (
                    <AlarmNav ref={ref_input} checkedState={checkedState} alarmTypeSelect={alarmTypeSelect} id={alarm.order_list} title={alarm.name} value={alarm.active_alarm_count} />
                  ))}
                </ul>
              </>
            ))}
          </div>
        </div>

        <div className="col-md-10 col-sm-12">
          <div class="row mb-1 mt-1"><div class="d-flex justify-items-center justify-content-between">
            <div class="page-title-right">
              {alarm_type.length > 0 ?
                alarm_type_data?.map((typeName, i) =>
                  <span class="badge bg-white shadow-sm rounded-pill text-dark" key={i}>{typeName['name']}  <i class="me-1 fas fa-times-circle" onClick={() => removeType(typeName['id'])}></i></span>
                ) : <span class="badge bg-white shadow-sm rounded-pill text-dark">All  <i class="me-1 fas fa-times-circle"></i></span>}
            </div>

          </div>
          </div>
          <div className="table-responsive custom-table-responsive scroll-side-nav">
            <table className="table custom-table text-center ">
              <tbody>

                {!selector_rms_alarms?.loading && selector_rms_alarms?.data?.alarms?.map((alarm, index) => (

                  <>
                    <tr className="bg-white">
                      <td width="7%">
                        <div className="signal-bars mt1 sizing-box good four-bars">
                          <span>
                            <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                          </span>
                          <div className={`first-bar bar ${alarm.network >= 1 ? '' : 'null-color'}`}>
                          </div>
                          <div className={`second-bar bar ${alarm.network >= 2 ? '' : 'null-color'}`}>
                          </div>
                          <div className={`third-bar bar ${alarm.network >= 3 ? '' : 'null-color'}`}>
                          </div>
                          <div className={`fourth-bar bar ${alarm.network >= 4 ? '' : 'null-color'}`}>
                          </div>
                          <div className={`fifth-bar bar ${alarm.network >= 5 ? '' : 'null-color'}`}>
                          </div>
                        </div>
                      </td>
                      <td width="6%" className="status" rel={alarm.id} data-value={alarm.site_id}>
                        <p className="tr-header">SITE ID</p>
                        <p className="tr-header-sub"><Link to={`/site/${alarm.site_id}`}>{alarm.site_id}</Link>
                        </p>
                      </td>
                      <td width="12%">
                        <p className="tr-header">Tenant</p>
                        <p className="tr-header-sub text-danger">{alarm.tenant_name}</p>
                      </td>
                      <td width="10%" >
                        <p className="tr-header">Alarm Source</p>
                        <p className="tr-header-sub text-danger">{alarm.source_name}</p>
                      </td>
                      <td width="25%">
                        <p className="tr-header">Alarm Name</p>
                        <p className="tr-header-sub text-danger">
                          {alarm.name}
                        </p>
                      </td>
                      <td width="15%">
                        <p className="tr-header">Start Time</p>
                        <p className="tr-header-date">
                          {alarm.alarm_start}

                        </p>
                      </td>
                      <td width="15%">
                        <p className="tr-header">End Time</p>
                        <p className="tr-header-date">
                          {alarm.alarm_end ?? '--'}
                        </p>
                      </td>
                      <td width="20%" className="elisp_time">
                        <p className="tr-header">Elapsed Time</p>
                        <p className="tr-header-sub elisp_time">
                        {alarm.alarm_end != null ?
               <span className="elisp_time">{alarm.total_elaspsed}</span> 
                :
                <CountdownTimer targetDate={alarm.start} />
              }
                         

                          </p>
                      </td>
                    </tr>
                    <tr className="spacer"><td colSpan={100} /></tr>
                  </>

                ))}
              </tbody>
            </table>

            {selector_rms_alarms?.data?.has_page ?
              <div class="float-md-center mb-2">
                <div class="btn-group ">
                  {selector_rms_alarms?.data?.first_page ?
                    <a class="btn btn-primary btn-md" style={{ cursor: 'no-drop' }}>Prev</a>
                    :
                    <a class="btn btn-primary btn-md " onClick={() => nextPage(selector_rms_alarms?.data?.prv_page_url)}>Prev</a>
                  }

                  {selector_rms_alarms?.data?.has_more_page ?
                    <a class="btn btn-info btn-md " onClick={() => nextPage(selector_rms_alarms?.data?.next_page_url)}>Next</a>
                    :
                    <a class="btn btn-info btn-md " style={{ cursor: 'no-drop' }}>Next</a>

                  }
                </div>
              </div> : ''
            }
          </div>
        </div>
      </div>
    </>
  )
}