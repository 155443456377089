import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import API from "../../Network/API";
import Paginate from "../../CustomHook/Paginate";
import SiteFilterModel from "../../Models/SiteFilterModel";
import { RMS_STATION_FETCH_SUCCESS } from "../../Reducer/RmsStationSlice";
import { RMS_ALARM_GROUP_FETCH_SUCCESS } from "../../Reducer/RmsAlarmGroupSlice";
import { Link } from "react-router-dom";

export default function RmsStation() {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [nxtPage, setNextPage] = useState(null);
    const [modalDialog, setModalDialog] = useState(false);
    const [siteId, setSiteID] = useState();

    const paginate = (pageNumber) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/rms_station?page=' + pageNumber).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    };

    useEffect(() => {

        all_door()

        API.get('/v1/oauth/rms_alarm_nav').then((response) => {
            dispatch(RMS_ALARM_GROUP_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {

        });

    }, []);



    const previousPage = (nxtPage) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get(nxtPage).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }

    const nextPage = (nxtPage) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get(nxtPage).then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }

    const searchSite = (site_code) => {
        API.get('/v1/oauth/rms_station?site_id=' + site_code).then((response) => {
            dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {

        });
    }


    const syncData = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/rms_station').then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });
    }





    const all_door = () => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/rms_station').then((response) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
            dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
            console.log(response.data.data)
            setLoading(false)
        }).catch((error) => {
            if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                loaderElement.setAttribute('hidden', 'true');
            }
        });

    }

    const selector_rms_alarm_nav = useSelector((state) => state.rmsAlarmGroupReducer)
    const selector = useSelector((state) => state.RmsStationReducer)
    const strAscending = selector?.sites.length > 0 && [...selector?.sites].sort((a, b) =>
        a.site_id > b.site_id ? 1 : -1,
    );

    return (
        <>

            <div className="row text-center  mb-2 mt-2 me-1 ms-1">


                <div className="d-flex justify-content-end">
                    <div class="input-group input-group-sm max-with-searbar">
                        <input type="text" onChange={(e) => searchSite(e.target.value)} class="form-control" placeholder="search by site id...." />
                        <button class="btn btn-success" type="button"><i class="fa-light fa-search" /></button>
                        <button class="btn btn-info" type="button" onClick={() => setModalDialog(!modalDialog)}><i class="fa-duotone fa-filter-list me-1" />Filter</button>
                    </div>
                    <button class="btn btn-warning  ms-2" type="button" onClick={() => syncData()}><i class="fa-light fa-refresh me-1" />Sync</button>


                    <div class="dropdown custom-dropdown-grop-btn ms-2">
                        <button class="btn btn-outline-primary bg-white text-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa-duotone fa-bell"></i> RMS Event ({selector_rms_alarm_nav?.data?.total_alarm > 0 ? <span className="badge bg-danger">{selector_rms_alarm_nav?.data?.total_alarm}</span> : 0})
                        </button>
                        <ul class="dropdown-menu">
                            {!selector_rms_alarm_nav?.loading && selector_rms_alarm_nav?.data?.rms_alarm_group?.map((rms_alarm, index) => (
                                <>
                                    <li className="ps-1 list-header">{rms_alarm.name}</li>
                                    <li><hr className="dropdown-divider" /></li>

                                    {rms_alarm?.alarm?.map((alarm, index) => (
                                        <li>
                                            <Link className="dropdown-item d-flex gap-2 align-items-center justify-content-between" to={`/rms_alarms?type=${alarm.order_list}`}>
                                                {alarm.name}
                                                <div className="form-check form-switch mb-0">
                                                    {alarm.active_alarm_count > 0 ? <span className="badge bg-danger">{alarm.active_alarm_count}</span> : <span className="badge bg-dark">0</span>}
                                                </div>
                                            </Link>
                                        </li>
                                    ))}


                                </>
                            ))}
                        </ul>
                    </div>



                </div>

            </div>

            <div className="row">
                <div className="table-responsive">

                    <table className="table rms_table table-bordered bg-white table-striped m-0 text-center">
                        <thead className="text-uppercase">
                            <tr>
                                <th className="toi-primary w-150">Last Updated</th>
                                <th className="text-center toi-primary" >Cluster</th>
                                <th className="text-center toi-primary" >Zone</th>
                                <th className="text-center toi-primary" >Site ID</th>
                                <th className="text-center toi-primary" >easi Site ID</th>
                                <th className="text-center toi-primary" >Site Name</th>
                                <th className="text-center toi-primary" >Site Category</th>
                                <th className="text-center toi-primary" >Network</th>
                                <th className="text-center toi-primary" >Remaining Backup</th>
                                <th className="text-center toi-primary w-50">Door Open</th>
                                <th className="text-center toi-primary w-50">Mains Fail</th>
                                <th className="text-center toi-primary w-50" >DC Low</th>
                                <th className="text-center toi-primary w-50" >Battery Critical</th>
                                <th className="text-center toi-primary w-50" >Module Fault</th>
                                <th className="text-center toi-primary w-50">LLVD</th>
                                <th className="text-center toi-primary w-50">PG ON</th>
                                <th className="text-center toi-primary w-50">MDB Fault</th>
                                <th className="text-center toi-primary w-50">ODC TEMP</th>
                                <th className="text-center toi-primary w-50">FAN FAULT</th>
                                <th className="text-center toi-primary w-50">SMOKE</th>
                                <th className="text-center toi-primary w-50">WATER LEAKAGE</th>
                                <th className="text-center toi-primary w-50">AVIATION LIGHT</th>
                                <th className="text-center toi-primary w-50">FUSE FAULT</th>
                                <th className="text-center toi-primary w-50">PHASE FAULT</th>
                                <th className="text-center toi-primary w-50">PHASE MISSING(R)</th>
                                <th className="text-center toi-primary w-50">PHASE MISSING(Y)</th>
                                <th className="text-center toi-primary w-50">PHASE MISSING(B)</th>
                                <th className="text-center toi-primary w-50">Overload</th>
                                <th className="text-center toi-primary w-50">Load Disconnect</th>
                         
                                <th className="text-center toi-primary w-50">Robi Site Down</th>
                                <th className="text-center toi-primary w-50">GP Site Down</th>
                                <th className="text-center toi-primary w-50">BL Site Down</th>
                                <th className="text-center toi-primary w-50">Telitalk Site Down</th>
                                <th className="text-center toi-primary w-50">Current(A)</th>
                                <th className="text-center toi-primary w-50">Temperature(°C)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!selector?.loading && selector?.sites.length > 0 && strAscending?.map((site, index) => (
                                <tr className={`${site.device_live_status == 0 ? 'offline-tr' : ''}`}>
                                    <td className="">
                                        <p className="m-0 p-0" style={{fontSize:'12px',width:'150px'}}>{site.update_time}</p>
                                        
                                    </td>
                                    <td className="text-center">
                                        {site.cluster_name}
                                    </td>
                                    <td className="text-center">
                                       {site.zone_name}
                                    </td>
                                    <td className="text-center">
                                        <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.site_id}</Link>
                                    </td>
                                    <td className="text-center">
                                        <Link className="fw-bold" to={`/site/${site.site_id}`}>{site.customer_site_id}</Link>
                                    </td>
                                    <td className="text-center">
                                        {site.site_name}
                                    </td>
                                    <td className="text-center">
                                        {site.site_category}
                                    </td>

                                    <td className="text-center">
                                        {site.device_live_status === 1 ?
                                            <div className="signal-bars mt1 sizing-box good four-bars">
                                                <span>
                                                    <img src="/assets/icon/6.svg" className="tower-height" alt="" />
                                                </span>
                                                <div className={`first-bar bar ${site.network_val >= 1 ? '' : 'null-color'}`}>
                                                </div>
                                                <div className={`second-bar bar ${site.network_val >= 2 ? '' : 'null-color'}`}>
                                                </div>
                                                <div className={`third-bar bar ${site.network_val >= 3 ? '' : 'null-color'}`}>
                                                </div>
                                                <div className={`fourth-bar bar ${site.network_val >= 4 ? '' : 'null-color'}`}>
                                                </div>
                                                <div className={`fifth-bar bar ${site.network_val >= 5 ? '' : 'null-color'}`}>
                                                </div>
                                            </div>
                                            :
                                            <i class="fa-solid fa-wifi-slash offline-rms-icon" title="Site not updating" />
                                        }
                                    </td>
                                    <td>

                                    </td>
                                    <td>
                                        {site?.conf_door_open==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.door_open == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.mains_fail == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.dc_low == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                   
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                      
                                    </td>
                                    <td>
                                        {site?.conf_rectifier_fault==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.rectifier_fault == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_llvd==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.llvd == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_pg_status==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.pg_status == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.mdb_fault == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_odc_temp_high==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.odc_temp_high == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_dc_fan_fault==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.dc_fan_fault == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_smoke==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.smoke == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_water_leakage==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.water_leakage == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_aviation_light_fault==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.aviation_light_fault == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_bat_load_fuse_fault==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.bat_load_fuse_fault == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.phase_fault == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_unknown_1==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.unknown_1 == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_unknown_2==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.unknown_2 == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_unknown_3==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.unknown_3 == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.is_overload == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.is_load_disconnect == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
    
                                 
                                    <td>
                                        {site?.conf_unknown_4==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.unknown_4 == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_unknown_5==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.unknown_5 == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_unknown_6==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.unknown_6 == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>
                                    <td>
                                        {site?.conf_unknown_7==0? <img src="/img/gray.png" alt="" className="rms-img-size offline-tr" title="Not Configure" />:site?.unknown_7 == 1 ?
                                            <img src="/assets/icon/circle-red-png.webp" alt="" className="rms-img-size blink" title="Critical" />
                                            :
                                            <img src="/assets/icon/check_green_circle.png" alt="" className="rms-alarm-img" title="Normal" />
                                        }
                                    </td>

                                    <td>{site.acur} A</td>
                                    <td>{site.temperature} °C</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector?.data.prv_page_url} nextPageUrl={selector?.data.next_page_url} postsPerPage={selector?.data.per_page} paginate={paginate} totalPosts={selector?.data.total_page} hasMorePage={selector?.data.has_more_page} onFirstPage={selector?.data.first_page} hasPage={selector?.data.has_page} currentPage={selector?.data.current_page} />
          
                </div>
            </div>

        </>
    )
}