import { useDispatch, useSelector } from "react-redux";
import HomeLockAlarmGrid from "../Component/HomeLockAlarmGrid";
import HomeRmsAlarmGrid from "../Component/HomeRmsAlarmGrid";

import API from "../Network/API";
import { useState, useEffect, useRef } from "react";
import { HOME_FETCH_SUCCESS } from "../Reducer/homeSlice";
import ReactEcharts from "echarts-for-react";
import echarts from 'echarts';
import { MemoCard } from "../CustomMemo/Card";
import { RMS_STATION_FETCH_SUCCESS } from "../Reducer/RmsStationSlice";
import RmsStationTable from "../Props/RmsStationTable";
import Map from "../Component/Map";
import AllLock from "../Component/Lock/AllLock";
import { DOOR_FETCH_SUCCESS } from "../Reducer/DoorSlice";
import { DOOR_STATUS_FETCH_SUCCESS } from "../Reducer/DoorStatusSlice";

export default function RMSMonitor() {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const echarts_react_1 = useRef();
  const echarts_react_2 = useRef();

  useEffect(() => {
    rms_home()
  }, []);



  const rms_home = () => {
    const loaderElement = document.querySelector(".loading-mask");
    API.get('/v1/oauth/home_overview').then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
        // loaderElement.remove();
      }
      dispatch(HOME_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
    });
  }

  const rms_station = () => {
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    API.get('/v1/oauth/rms_station').then((response) => {
      if (loaderElement && !loaderElement.hasAttribute('hidden')) {
        loaderElement.setAttribute('hidden', 'true');
      }
      dispatch(RMS_STATION_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
    });
  }

  const all_door = () => {
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    API.get('/v1/oauth/all_door').then((response) => {
      if (loaderElement && !loaderElement.hasAttribute('hidden')) {
        loaderElement.setAttribute('hidden', 'true');
      }
      dispatch(DOOR_FETCH_SUCCESS(response.data.data))
      setLoading(false)
    }).catch((error) => {
      setLoading(true)
    });


    API.get('/v1/oauth/door_status').then((response) => {

      dispatch(DOOR_STATUS_FETCH_SUCCESS(response.data.data))

    }).catch((error) => {

    });


  }

  const selector = useSelector((state) => state.homeReducer)
  const alarms = selector?.data

  const option = {
    color: ["#ffa91c", "#fd3b48", "#f96a74", "#ffc61c"],
    tooltip: {

      trigger: 'item',
      formatter: '{a} <br/>{b} ({c})',
      axisPointer: {
        animation: true
      },
      backgroundColor: "#333333e8",
      textStyle: {
        color: '#fff'
      }

    },
    legend: {
      top: '25%',
      right: '25%',
      orient: 'vertical',
      right: 'left',
      textStyle: {
        fontSize: 11
      },
      data: [
        'Mains Fail',
        'DC Low',
        'MDB Fault',
        'Phase Fault',
      ],
      padding: [
        0, // up
        0, // right
        0, // down
        10, // left
      ]
    },
    series: [{
      name: 'Power',
      type: 'pie',
      selectedMode: 'single',
      radius: [0, 0],
      label: {
        position: 'inner',
        fontSize: 16,
        color: '#000000'
      },
      labelLine: {
        show: false
      },
      right: '35%',
      data: [{
        value: 0,
        name: 'Power'
      }]
    },
    {
      name: 'Power',
      type: 'pie',
      radius: ['35%', '60%'],
      label: {
        show: true,
        formatter: '{c}',
        position: 'inside'
      },
      itemStyle: {
        borderRadius: 1,
        borderColor: '#fff',
      },
      labelLine: {
        show: false
      },
      right: '35%',
      data: [{
        value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0,
        name: 'Mains Fail'
      },
      {
        value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_low : 0,
        name: 'DC Low'
      },
      {
        value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mdb_fault : 0,
        name: 'MDB Fault'
      },
      {
        value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.phase_missing : 0,
        name: 'Phase Fault'
      }
      ]
    }
    ]
  };

  const option2 = {

    tooltip: {

      trigger: 'item',
      formatter: '{a} <br/>{b} ({c})',
      axisPointer: {
        animation: true
      },
      backgroundColor: "#333333e8",
      textStyle: {
        color: '#fff'
      }

    },
    legend: {
      top: '25%',
      right: '25%',
      orient: 'vertical',
      right: 'left',
      textStyle: {
        fontSize: 11
      },
      data: [
        'Smoke',
        'DC Fan Fault',
        'Rectifier Fault',
        'Water Leakage',
        'ODC Temp High',
        'Door Open',
      ],
      padding: [
        0, // up
        0, // right
        0, // down
        10, // left
      ]
    },
    series: [{
      name: 'Sensor',
      type: 'pie',
      selectedMode: 'single',
      radius: [0, 0],
      label: {
        position: 'inner',
        fontSize: 16,
        color: '#000000'
      },
      labelLine: {
        show: false
      },
      right: '35%',
      data: [{
        value: 0,
        name: 'Sensor'
      }]
    },
    {
      name: 'Sensor',
      type: 'pie',
      radius: ['35%', '60%'],
      label: {
        show: true,
        formatter: '{c}',
        position: 'inside'
      },
      itemStyle: {
        borderRadius: 1,
        borderColor: '#fff',
      },
      labelLine: {
        show: false
      },
      right: '35%',
      data: [
        {
          value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.smoke : 0,
          name: 'Smoke'
        },
        {
          value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_fan_fault : 0,
          name: 'DC Fan Fault'
        },
        {
          value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.rectifier_fault : 0,
          name: 'Rectifier Fault'
        },
        {
          value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.water_leakage : 0,
          name: 'Water Leakage'
        },

        {
          value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.temp_high_sp : 0,
          name: 'ODC Temp High'
        },
        {
          value: alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.door_open : 0,
          name: 'Door Open'
        },
      ]
    }
    ]
  };

  const option1 = {
    color: ["#FF3131", "#64c5b1", "#ffc61c", "#34d3eb", "#0cc345", "#fd3b48", "#5553ce", "#fd7e14", "#ef1047"],
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}',
      axisPointer: {
        animation: true
      },
      backgroundColor: "#333333e8",
      textStyle: {
        color: '#fff'
      }

    },
    legend: {
      orient: 'vertical',
      left: 'right',
    },
    series: [{
      name: 'STATISTICS',
      type: 'pie',
      label: {
        show: false,
        formatter: '{c}',
        position: 'inside'
      },
      radius: ['80%'],
      top: "-20px",
      right: '35%',
      itemStyle: {
        borderRadius: 1,
        borderColor: '#ccc',
      },
      data: alarms.total_lock_alarm,

      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }]
  };


  return (
    <>
      <div className="custom-tab ">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Overview</button>
            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" tabIndex={-1} onClick={() => rms_station()}>RMS Station</button>
            <button className="nav-link" id="nav-lock-tab" data-bs-toggle="tab" data-bs-target="#nav-lock" type="button" role="tab" aria-controls="nav-lock" aria-selected="false" tabIndex={-1} onClick={() => all_door()}>Access Lock</button>
            <button className="nav-link" id="nav-graph-tab" data-bs-toggle="tab" data-bs-target="#nav-graph" type="button" role="tab" aria-controls="nav-graph" aria-selected="false" tabIndex={-1}>Graph</button>
            <button className="nav-link" id="nav-map-tab" data-bs-toggle="tab" data-bs-target="#nav-map" type="button" role="tab" aria-controls="nav-map" aria-selected="false" tabIndex={-1}>Map</button>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex={0}>
            <div className="row pt-3 g-1 ms-1">
              <div className="col-md-2 col-6">

                <MemoCard title={`Total Site`} value={alarms?.total_sites} route={`#`} icon={`fa-light fa-network-wired`} color={`bg-primary`} />
              </div>
              <div className="col-md-2 col-6">
                <MemoCard title={`Total Lock`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.total_site : 0} route={`#`} icon={`fa-light fa-lock`} color={`bg-dark`} />
              </div>
              <div className="col-md-2 col-6">
                <MemoCard title={`Total Online`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.online_device : 0} route={`#`} icon={`fa-light fa-wifi`} color={`bg-success`} />
              </div>
              <div className="col-md-2 col-6">
                <MemoCard title={`Total Offline`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.offline_device : 0} route={`#`} icon={`fa-light fa-wifi-slash`} color={`bg-danger`} />
              </div>
              <div className="col-md-2 col-6">
                <MemoCard title={`Door Open`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.door_status : 0} route={`#`} icon={`fa-light fa-door-open`} color={`bg-warning`} />
              </div>
              <div className="col-md-2 col-6">

                <MemoCard title={`Security Breach`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.breach_status : 0} route={`#`} icon={`fa-light fa-user-ninja`} color={`bg-danger`} />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-2">
                <HomeRmsAlarmGrid />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-2">
                <HomeLockAlarmGrid />
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 mt-2">
                <div className="card ms-1">
                  <div class="card-header  border-bottom card-title"><i class="fa-light fa-circle-info me-1" /> RMS Alarm Grapical Analytics
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <ReactEcharts  ref={echarts_react_1} style={{ height: "260px" }} option={option} />

                      </div>
                      <div className="col-md-6">
                        <ReactEcharts ref={echarts_react_2} style={{ height: "260px" }} option={option2} />
                      </div>



                    </div>
                  </div>
                </div>

              </div>

              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-2">
                <div className="card">
                  <div class="card-header  border-bottom card-title"><i class="fa-light fa-circle-info me-1" /> RMS Alarm Grapical Analytics
                  </div>
                  <div className="card-body">
                    <ReactEcharts ref={echarts_react_2} style={{ height: "260px" }} option={option1} />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex={0}>

            <div className="table-responsive-lg">
              <RmsStationTable />
            </div>
          </div>

          <div className="tab-pane fade" id="nav-lock" role="tabpanel" aria-labelledby="nav-lock-tab" tabIndex={0}>

            <div className="table-responsive-lg bg-white">
              <AllLock />
            </div>
          </div>


          <div className="tab-pane fade" id="nav-map" role="tabpanel" aria-labelledby="nav-map-tab" tabIndex={0}>
            <div id="map">
              <Map />
            </div>

          </div>
        </div>
      </div>
    </>
  )
}