import { useState } from "react"
import { useEffect } from "react"
import { useRef } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { SITE_ID_FETCH_SUCCESS } from "../../../../Reducer/SiteIdSlice"
import API from "../../../../Network/API"
import DcTab from "../DcTab"
import ReactEcharts from "echarts-for-react";
import * as echarts from 'echarts'
export default function DcGraph() {
    const [rms, setRms] = useState()
    const [dc_kwh_month, setDcMonthkWh] = useState()
    const [dc_hourly, setDcHourlyKwh] = useState()
    const [dc_voltage, setDcVoltage] = useState()
    const [dc_kw_avg_hourly, setDcAvgHourly] = useState()
    const [dc_kwMonthly, setDckwMonth] = useState()
    const echarts_react_1 = useRef();
    const echarts_react_2 = useRef();
    const echarts_react_3 = useRef();
    const echarts_react_4 = useRef();
    const echarts_react_5 = useRef();
    const button_1 = useRef();
    const button_2 = useRef();
    const ddd = useParams();
    const dispatch = useDispatch()
    const [dateValue, setDateChange] = useState(new Date().toISOString().slice(0, 10))
    const [to_date, setToDateChange] = useState()
    const date = '2023-02-01'
    const [step, setStepNumber] = useState(1)

    const onDateChange = (value) => {
        setDateChange(value)
        if (step == 1) {
            dc_voltage_load(value)
        } else if (step == 2) {
            dc_kwh_data(value)
        } else if (step == 4) {
            dc_kw_hourly(value)
        }
    }

    const dc_voltage_load = (value) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/dc_voltage?site_id=' + ddd.id + '&date=' + value).then((response) => {
            setDcVoltage(response.data)
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setDcVoltage(response.data)
        }).catch((error) => {

            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }

        });
    }


    const dc_kw_monthly = (value, value2) => {
        var date_to = value2 == undefined ? '' : value2
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/dc_kw_month?site_id=' + ddd.id + '&date=' + value + '&date2=' + date_to).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setDckwMonth(response.data)
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    const dc_kw_hourly = (value) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/dc_avg_kw_hourly?site_id=' + ddd.id + '&date=' + value).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setDcAvgHourly(response.data)
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    const setStep = (number) => {
        setStepNumber(number)
        if (number == 1) {
            dc_voltage_load(dateValue)
        } else if (number == 2) {
            button_1.current.click()
            dc_kwh_data(dateValue)
        }
        else if (number == 3) {
            button_1.current.click()
            dc_kwh_month_data(dateValue, to_date)
        }
        else if (number == 4) {
            button_2.current.click()
            dc_kw_hourly(dateValue)
            // dc_kwh_month_data(dateValue,to_date)
        } else if (number == 5) {
            button_2.current.click()
            dc_kw_monthly(dateValue, to_date)
        }

    }

    const dc_kwh_month_data = (value1, value2) => {

        var date_to = value2 == undefined ? '' : value2
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/monthlyDCKwhReport?site_id=' + ddd.id + '&date=' + value1 + '&date2=' + date_to).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setDcMonthkWh(response.data)

        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }

    const dc_kwh_data = (value) => {
        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        API.get('/v1/oauth/hourly_dc_kwh_report?site_id=' + ddd.id + '&date=' + value).then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
            setDcHourlyKwh(response.data)
        }).catch((error) => {

            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });
    }


    const searchMonthData = () => {
        if (step == 3) {
            dc_kwh_month_data(dateValue, to_date)
        } else if (step == 5) {
            dc_kw_monthly(dateValue, to_date)
        }

    }



    useEffect(() => {

        const loaderElement = document.querySelector(".loading-mask");
        loaderElement.removeAttribute('hidden');
        loaderElement.setAttribute('visible', 'true');
        dispatch(SITE_ID_FETCH_SUCCESS(ddd.id))
        // API.get('/v1/oauth/dc_dashboard/' + ddd.id).then((response) => {
        //     if (loaderElement) {
        //         if (loaderElement && !loaderElement.hasAttribute('hidden')) {
        //             loaderElement.setAttribute('hidden', 'true');
        //         }
        //     }

        // }).catch((error) => {

        //     if (loaderElement) {
        //         if (loaderElement && !loaderElement.hasAttribute('hidden')) {
        //             loaderElement.setAttribute('hidden', 'true');
        //         }
        //     }
        // });


        API.get('/v1/oauth/dc_voltage?site_id=' + ddd.id + '&date=' + dateValue).then((response) => {
            setDcVoltage(response.data)
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        }).catch((error) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
            }
        });


    }, [ddd.id]);


    const option = {
        tooltip: {
            trigger: 'axis',
            position: function (pt) {
                return [pt[0], '10%'];
            }
        },
        title: {
            left: 'center',
            text: 'DC Voltage Chart '
        },
        toolbox: {
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dc_voltage?.times
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '100%']
        },
        // dataZoom: [
        //   {
        //     type: 'inside',
        //     start: 0,
        //     end: 10
        //   },
        //   {
        //     start: 0,
        //     end: 10
        //   }
        // ],
        series: [
            {
                name: 'Voltage',
                type: 'line',
                symbol: 'none',
                sampling: 'lttb',
                itemStyle: {
                    color: 'rgb(255, 70, 131)'
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgb(0, 120, 207)'
                        },
                        {
                            offset: 1,
                            color: 'rgb(0, 20, 107)'
                        }
                    ])
                },
                data: dc_voltage?.voltage
            }
        ]
    }

    const option_kwh = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {

        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: dc_hourly?.data?.times
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} kWh'
            },
            axisPointer: {
                snap: true
            }
        },

        series: dc_hourly?.data?.lebel
    }

    const option_kw = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {

        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: dc_kw_avg_hourly?.data?.times
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} kW'
            },
            axisPointer: {
                snap: true
            }
        },

        series: dc_kw_avg_hourly?.data?.lebel
    }

    const option_kwh_month = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {

        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: dc_kwh_month?.times
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} kWh'
            },
            axisPointer: {
                snap: true
            }
        },
        series: dc_kwh_month?.lebel
    }

    const option_kw_month = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {

        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: {}
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            // prettier-ignore
            data: dc_kwMonthly?.times
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value} kW'
            },
            axisPointer: {
                snap: true
            }
        },
        series: dc_kwMonthly?.lebel
    }
    return (
        <>
            <div className="row">
                <DcTab id={2} site_id={ddd.id} />
            </div>

            <div className="row ms-1 me-1">
                <div className="col-12 text-center">
                    <div class="btn-group btn-group-md">
                        <button type="button" class={`btn btn-outline-primary   ${step == 1 ? 'btn-primary text-white' : 'bg-white'}`} onClick={() => setStep(1)}>DC Voltage</button>

                        <div class="btn-group">
                            <button ref={button_1} type="button" class={`btn btn-outline-primary dropdown-toggle ${step == 2 || step == 3 ? 'btn-primary text-white' : 'bg-white'}`} data-bs-toggle="dropdown">{step == 2 ? 'DC kWh (Hourly)' : step == 3 ? 'DC kWh (Daily)' : 'DC kWh (Hourly)'}</button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#" onClick={() => setStep(2)}>DC kWh (Hourly)</a>
                                <a class="dropdown-item" href="#" onClick={() => setStep(3)}>DC kWh (Daily)</a>
                            </div>
                        </div>

                        <div class="btn-group">
                            <button ref={button_2} class={`btn btn-outline-primary dropdown-toggle ${step == 4 || step == 5 ? 'btn-primary text-white' : 'bg-white'}`} type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {step == 4 ? ' DC Power (Hourly)' : step == 5 ? ' DC Power(Daily)' : ' DC Power (Hourly)'}
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#" onClick={() => setStep(4)}>DC Power(Hourly)</a>
                                <a class="dropdown-item" href="#" onClick={() => setStep(5)}>DC Power(Daily)</a>
                            </div>
                        </div>
                        {/* <button type="button" class={`btn btn-outline-primary ${step == 6 ? 'btn-primary text-white' : 'bg-white'}`} onClick={() => setStep(6)}>Load(W)</button> */}
                    </div>
                </div>
                <div className="col-md-12">

                    <div className="card shadow-sm border-0 bg-cc mt-2" style={{ minHeight: '300px' }}>
                        <div class="card-header  border-bottom card-title bg-white align-items-center d-flex">

                            <b class="main-card-title mb-0 flex-grow-1">{step == 1 ? 'DC Voltage' : step == 2 ? 'DC kWh(Hourly)' : step == 3 ? 'DC kWh(Daily)' : step == 4 ? 'DC Power(Hourly)' : step == 5 ? 'DC Power(Daily)' : step == 6 ? 'DC Load(W)' : ''}</b>
                            <div class="flex-shrink-0">
                                <div class="">
                                    <div class="input-group input-group-sm">

                                        <input value={dateValue} autoFocus={true} onChange={(e) => onDateChange(e.target.value)} type="date" class="form-control" placeholder="Search..." autocomplete="off" />
                                        {step == 3 || step == 5 ?
                                            <>
                                                <span class="input-group-text">To</span>
                                                <input value={to_date} autoFocus={true} onChange={(e) => setToDateChange(e.target.value)} type="date" class="form-control" placeholder="Search..." autocomplete="off" />
                                                <a onClick={() => searchMonthData()} href="#" class="input-group-text"><i className="fa-light fa-search" /></a>
                                            </>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {step == 1 ?
                            <div className="card-body bg-white">

                                <ReactEcharts lazyUpdate={true} notMerge={true} ref={echarts_react_1} style={{ height: "300px" }} option={option} />


                            </div>
                            :
                            step == 2 ?
                                <div className="card-body bg-white">
                                    <ReactEcharts lazyUpdate={true} notMerge={true} ref={echarts_react_2} style={{ height: "300px" }} option={option_kwh} />
                                </div>
                                : step == 3 ?
                                    <div className="card-body bg-white">
                                        <ReactEcharts notMerge={true} ref={echarts_react_3} style={{ height: "300px" }} option={option_kwh_month} />
                                    </div>
                                    : step == 4 ?
                                        <div className="card-body bg-white">
                                            <ReactEcharts notMerge={true} ref={echarts_react_4} style={{ height: "300px" }} option={option_kw} />
                                        </div>
                                        : step == 5 ?
                                            <div className="card-body bg-white">
                                                <ReactEcharts notMerge={true} ref={echarts_react_5} style={{ height: "300px" }} option={option_kw_month} />
                                            </div>
                                            : ''
                        }
                    </div>
                </div>
            </div>
        </>
    )
}