import { useEffect, useState } from "react";
import API from "../Network/API";
import { useDispatch, useSelector } from "react-redux";
import { RMS_ALARM_GROUP_FETCH_SUCCESS } from "../Reducer/RmsAlarmGroupSlice";
import { LOCK_ALARM_FETCH_SUCCESS } from "../Reducer/LockAlarmListSlice";
import { AlarmNav } from "../CustomMemo/AlarmNav";
import Breadcrumb from "../CustomHook/Breadcrumb";
import { SiteCodeTR } from "../CustomMemo/SiteCodeTR";
import { ALARM_SITE_CODE_FETCH_SUCCESS } from "../Reducer/AlarmSiteCodeSlice";
import { TIMELINE_FETCH_SUCCESS } from "../Reducer/TimelineSlice";
import Timeline from "../Props/Timeline";
import Paginate from "../CustomHook/Paginate";
import { Link } from "react-router-dom/dist";

import { DOOR_ALARM_TYPE_FETCH_SUCCESS } from "../Reducer/DoorAlarmTypeSlice";
import { DOOR_SITE_CODE_FETCH_SUCCESS } from "../Reducer/DoorSiteCodeSlice";
import { RmsAlarmNav } from "../CustomMemo/RmsAlarmNav";
import { RMS_ALARM_TYPE_FETCH_SUCCESS } from "../Reducer/RmsAlarmTypeSlice";

export default function RealtimeAlarm() {
  const [nxtPageAlarm, setNextPageAlarm] = useState(null);
  const [nxtPage, setNextPage] = useState(null);
  const dispatch = useDispatch();
  const [alarm_type, setAlarmType] = useState([])
  const [alarm_type_data, setAlarmTypeData] = useState([])
  const [rms_alarm_type, setRmsAlarmType] = useState([])
  const [rms_alarm_type_data, setRmsAlarmTypeData] = useState([])
  const [site_code, setSiteCode] = useState([])
  const [step, setStep] = useState(0)

  const loadMore = (page) => {
    const loaderElement = document.querySelector(".loading-mask");
    API.get('/v1/oauth/alarm_sites?page=' + page).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(ALARM_SITE_CODE_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {

    });
  }

  const previousPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  const nextPage = (nxtPage) => {
    setNextPage(nxtPage)
  }

  // rms lock type


  const alarmTypeSelect = (event) => {
    if (event.target.checked) {
      if (alarm_type.indexOf(parseInt(event.target.value)) === -1) {
        setAlarmType([
          ...alarm_type, parseInt(event.target.value)
        ])
        dispatch(DOOR_ALARM_TYPE_FETCH_SUCCESS(parseInt(event.target.value)))
        setAlarmTypeData([...alarm_type_data, {
          'id': parseInt(event.target.value),
          'name': event.target.title,
        }])

      }

    } else {
      dispatch(DOOR_ALARM_TYPE_FETCH_SUCCESS(parseInt(event.target.value)))

      removeID(parseInt(event.target.value))
      removeType(parseInt(event.target.value))
    }

  }

  const removeType = (u_id) => {
    setAlarmTypeData(alarm_type_data.filter(a =>
      a.id !== u_id
    ))
    document.querySelector('.door_type_' + u_id).click();
    removeID(u_id)
  }

  const removeID = (url) => {
    const index = alarm_type.indexOf(url);
    if (index > -1) { // only splice array when item is found
      alarm_type.splice(index, 1); // 2nd parameter means remove one item only
    }
    setAlarmType(alarm_type)
  }


  // start rms alarm type

  const rmsAlarmTypeSelect = (event) => {

    if (event.target.checked) {
      if (rms_alarm_type.indexOf(parseInt(event.target.value)) === -1) {
        setRmsAlarmType([
          ...rms_alarm_type, parseInt(event.target.value)
        ])
        dispatch(RMS_ALARM_TYPE_FETCH_SUCCESS(parseInt(event.target.value)))
        setRmsAlarmTypeData([...rms_alarm_type_data, {
          'id': parseInt(event.target.value),
          'name': event.target.title,
        }])

      }

    } else {
      dispatch(RMS_ALARM_TYPE_FETCH_SUCCESS(parseInt(event.target.value)))

      removeIDRms(parseInt(event.target.value))
      removeTypeRms(parseInt(event.target.value))
    }

  }

  const removeTypeRms = (u_id) => {
    setRmsAlarmTypeData(rms_alarm_type_data.filter(a =>
      a.id !== u_id
    ))
    document.querySelector('.rms_alrm_type_' + u_id).click();
    removeIDRms(u_id)
  }

  const removeIDRms = (url) => {
    const index = rms_alarm_type.indexOf(url);
    if (index > -1) { // only splice array when item is found
      rms_alarm_type.splice(index, 1); // 2nd parameter means remove one item only
    }
    setRmsAlarmType(rms_alarm_type)
  }



  useEffect(() => {
    load_data()
  }, []);

  useEffect(() => {
    if (nxtPageAlarm != null) {
      var pagea = nxtPageAlarm
    } else {
      var pagea = '/v1/oauth/timeline'
    }
    const loaderElement = document.querySelector(".loading-mask");
    API.get(pagea).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(TIMELINE_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {

    });

  }, [nxtPageAlarm]);

  useEffect(() => {
    if (nxtPage != null) {
      var page = nxtPage
    } else {
      var page = '/v1/oauth/alarm_sites'
    }
    const loaderElement = document.querySelector(".loading-mask");
    API.get(page).then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(ALARM_SITE_CODE_FETCH_SUCCESS(response.data.data))

    }).catch((error) => {
    });

  }, [nxtPage]);

  const load_data = () => {
    const loaderElement = document.querySelector(".loading-mask");
    loaderElement.removeAttribute('hidden');
    loaderElement.setAttribute('visible', 'true');
    API.get('/v1/oauth/rms_alarm_nav').then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(RMS_ALARM_GROUP_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {

    });

    API.get('/v1/oauth/lock_alarm_nav').then((response) => {
      if (loaderElement) {
        if (loaderElement && !loaderElement.hasAttribute('hidden')) {
          loaderElement.setAttribute('hidden', 'true');
        }
      }
      dispatch(LOCK_ALARM_FETCH_SUCCESS(response.data.data))
    }).catch((error) => {

    });
  }


  const checkedSiteId = (event) => {
    if (event.target.checked) {
      if (site_code.indexOf(event.target.value) === -1) {
        setSiteCode([
          ...site_code, event.target.value
        ])
        dispatch(DOOR_SITE_CODE_FETCH_SUCCESS(event.target.value))
        setStep(1)
        // setNextPageAlarm('/v1/oauth/lock_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code+'&start_date='+start_date+'&end_date='+end_date)
      }
    }
    else {
      dispatch(DOOR_SITE_CODE_FETCH_SUCCESS(event.target.value))
      setStep(1)
      removeSiteID(event.target.value)
    }
  }



  const removeSiteID = (u_id) => {
    setSiteCode(site_code.filter(a =>
      a !== u_id
    ))
    // setNextPageAlarm('/v1/oauth/lock_alarm_history?page=1&query=' + alarm_type + '&site_code=' + site_code+'&start_date='+start_date+'&end_date='+end_date)
  }

  const selector_lock = useSelector((state) => state.lockAlarmListReducer)
  const selector_rms_alarm = useSelector((state) => state.rmsAlarmGroupReducer)
  const selector_alarm_site_code = useSelector((state) => state.alarmSiteCodeReducer)
  const selector_timeline = useSelector((state) => state.timelineSlice)

  return (
    <>

      <div className="row mb-1 mt-1">
        <div className="d-flex justify-items-center justify-content-between">
          <div class="page-title-right">
            <ol class="breadcrumb ms-1  mb-0">
              <li class="breadcrumb-item">
                <Link to={`/home`}>Home</Link>
              </li>
              <li class="breadcrumb-item active">Timeline</li>
            </ol>
          </div>
          <div className="d-flex" style={{ alignItems: 'center' }}>
            <div className="search-box me-2 position-relative">
              <input type="text" className="form-control border-radius-20" id="searchResultList" placeholder="Search site id..." />
              <i className="fa-light fa-search search-icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="section-body">
        <div className="row">
          <div className="col-md-2">
            <div className="scroll-side-nav">

              <ul className="list-group alarm-list mb-2 ">
                <li className="list-group-item d-flex justify-content-between align-items-center align-items-center title">
                  <h6 className="m-0 p-0">Access</h6>
                </li>
                {!selector_lock?.loading && selector_lock?.data?.lock_alarms?.map((lock_alarm, index) => (
                  <AlarmNav id={lock_alarm.order_list} title={lock_alarm.name} value={lock_alarm.value} alarmTypeSelect={alarmTypeSelect} />
                ))}
              </ul>

              {!selector_rms_alarm?.loading && selector_rms_alarm?.data?.rms_alarm_group?.map((rms_alarm, index) => (
                <>
                  <ul className="list-group alarm-list mb-2 ">
                    <li className=" list-group-item d-flex justify-content-between align-items-center align-items-center title">
                      <h6 className="m-0 p-0">{rms_alarm.name}</h6>
                    </li>
                    {rms_alarm?.alarm?.map((alarm, index) => (
                      <RmsAlarmNav id={alarm.order_list} title={alarm.name} value={alarm.active_alarm_count} rmsAlarmTypeSelect={rmsAlarmTypeSelect} />
                    ))}
                  </ul>
                </>
              ))}
            </div>
          </div>
          <div className="col-md-6">

            <table className="table table-striped  table-bordered m-0 text-center bg-white">
              <thead className="text-uppercase toi-primary">
                <tr>
                  <th></th>
                  <th>Easi Site ID</th>
                  <th>Site ID</th>
                  <th>Cluster</th>
                  <th>Zone</th>
                </tr>
              </thead>
              <tbody>
                {!selector_alarm_site_code?.loading && selector_alarm_site_code?.data?.sites?.map((site, index) => (
                  <SiteCodeTR site_id={site.site_id} customer_site_id={site.customer_site_id} cluster={site.cluster} zone={site.zone} />
                ))}
              </tbody>
            </table>
            <Paginate nextPage={nextPage} previousPage={previousPage} previousPageUrl={selector_alarm_site_code?.data.prv_page_url} nextPageUrl={selector_alarm_site_code?.data.next_page_url} postsPerPage={selector_alarm_site_code?.data.per_page} paginate={loadMore} totalPosts={selector_alarm_site_code?.data.total_page} hasMorePage={selector_alarm_site_code?.data.has_more_page} onFirstPage={selector_alarm_site_code?.data.first_page} hasPage={selector_alarm_site_code?.data.has_page} currentPage={selector_alarm_site_code?.data.current_page} />

          </div>
          <div className="col-md-4">
            <div className="header-title align-items-center">
              <div className="list_alarm mb-3">
                {alarm_type.length > 0 || rms_alarm_type.length > 0 || site_code.length > 0 ?
                  <div className="card-body">

                    {alarm_type.length > 0 ?
                      alarm_type_data?.map((typeName, i) =>
                        <span class="badge bg-white shadow-sm rounded-pill text-dark" key={i}>{typeName['name']}  <i class="me-1 fas fa-times-circle" onClick={() => removeType(typeName['id'])}></i></span>
                      ) : ''}
                    {alarm_type.length > 0 && site_code.length > 0 ?
                      <hr className="m-1 p-0" /> : ''
                    }

                    {rms_alarm_type.length > 0 ?
                      rms_alarm_type_data?.map((typeName, i) =>
                        <span class="badge bg-white shadow-sm rounded-pill text-dark" key={i}>{typeName['name']}  <i class="me-1 fas fa-times-circle" onClick={() => removeTypeRms(typeName['id'])}></i></span>
                      ) : ''}
                    {rms_alarm_type.length > 0 && site_code.length > 0 ?
                      <hr className="m-1 p-0" /> : ''
                    }

                    {site_code.length > 0 ?
                      <>

                        {site_code?.map((st, i) =>
                          <span class="badge bg-white shadow-sm rounded-pill text-dark">{st} <i class="me-1 fas fa-times-circle" onClick={() => removeSiteID(st)}></i></span>
                        )}
                      </>
                      : ''}
                  </div>
                  : ''}
              </div>
            </div>
            <div className="card-box ">

              <div className="scroll-side-nav">
                <div className="timeline">
                  <div className="line text-muted" />
                  {!selector_timeline?.loading && selector_timeline?.data?.alarms?.map((timeAarm, index) => (
                    <Timeline data={timeAarm} />
                  ))}

                  {selector_timeline?.data?.has_page ?
                    <div class="float-md-end">
                      <div class="btn-group ">
                        {selector_timeline?.data?.first_page ?
                          <a class="btn btn-primary btn-sm" style={{ cursor: 'no-drop' }}>Prev</a>
                          :
                          <a class="btn btn-primary btn-sm " onClick={() => setNextPageAlarm(selector_timeline?.data?.prv_page_url)}>Prev</a>
                        }

                        {selector_timeline?.data?.has_more_page ?
                          <a class="btn btn-info btn-sm " onClick={() => setNextPageAlarm(selector_timeline?.data?.next_page_url)}>Next</a>
                          :
                          <a class="btn btn-info btn-sm " style={{ cursor: 'no-drop' }}>Next</a>

                        }
                      </div>
                    </div> : ''
                  }

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}