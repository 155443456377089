import React from 'react';
export function SiteCodeTR({ site_id,customer_site_id,cluster,zone }) { 
    return (
        <>
         <tr>
            <td><input type='checkbox'/></td>
            <td>{customer_site_id}</td>
            <td>{site_id}</td>
            <td>{cluster}</td>
            <td>{zone}</td>
        </tr>
        </>
       
    );
}
export const MemoSiteCodeTR = React.memo(SiteCodeTR);