import { useEffect } from "react";
import HomeRmsAlarmGrid from "../Component/HomeRmsAlarmGrid";
import HomeLockAlarmGrid from "../Component/HomeLockAlarmGrid";
import { useDispatch, useSelector } from "react-redux";
import { HOME_FETCH_SUCCESS } from "../Reducer/homeSlice";
import API from "../Network/API";
import { useState } from "react";
import RmsTimeline from "../Component/Rms/home/RmsTimeline";
import HomeGrid from "../CustomHook/HomeGrid";
import ReactEcharts from "echarts-for-react";
import { useRef } from "react";
import { RMS_ACTIVE_ALARM_FETCH_SUCCESS } from "../Reducer/RmsActiveAlarmSlice";
import { ACS_ACTIVE_ALARM_FETCH_SUCCESS } from "../Reducer/AcsActiveAlarmSlice";
import PgRunningStatus from "../Component/Rms/home/PgRunningStatus";
import AcsTimeline from "../Component/Rms/home/AcsTimeline";
import { DateRange } from "react-date-range";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default function Home() {
    const echarts_react_2 = useRef();
    const [isLoading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const [equip_data,setEqipmentData]=useState();


    useEffect(() => {
        rms_home();
        rms_active_alarm();
        acs_active_alarm();
        sync_equipment()
    }, []);

    const rms_home = () => {
        const loaderElement = document.querySelector(".loading-mask");
        API.get('/v1/oauth/home_overview').then((response) => {
            if (loaderElement) {
                if (loaderElement && !loaderElement.hasAttribute('hidden')) {
                    loaderElement.setAttribute('hidden', 'true');
                }
                // loaderElement.remove();
            }
            dispatch(HOME_FETCH_SUCCESS(response.data.data))
            setLoading(false)
        }).catch((error) => {
            setLoading(true)
            if(error.response.status==401 && error.response.statusText=="Unauthorized"){
                localStorage.clear();
                window.location.href = '/';
            }
            
        });
    }

    const sync_equipment = () => {
        API.get('/v1/oauth/home_equipment_disconnected').then((response) => {
            setEqipmentData(response.data.data)
        }).catch((error) => {
        });
    }

    const rms_active_alarm = () => {
        API.get('/v1/oauth/rms_active_alarm').then((response) => {
            dispatch(RMS_ACTIVE_ALARM_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
        });
    }

    const acs_active_alarm = () => {
        API.get('/v1/oauth/acs_active_alarm').then((response) => {
            dispatch(ACS_ACTIVE_ALARM_FETCH_SUCCESS(response.data.data))
        }).catch((error) => {
        });
    }

    const selector = useSelector((state) => state.homeReducer)
    const alarms = selector?.data

    const option1 = {
        color: ["#FF3131", "#64c5b1", "#ffc61c", "#34d3eb", "#0cc345", "#fd3b48", "#5553ce", "#fd7e14", "#ef1047"],
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}',
            axisPointer: {
                animation: true
            },
            backgroundColor: "#333333e8",
            textStyle: {
                color: '#fff'
            }

        },
        legend: {
            orient: 'vertical',
            left: 'right',
        },
        series: [{
            name: 'STATISTICS',
            type: 'pie',
            label: {
                show: false,
                formatter: '{c}',
                position: 'inside'
            },
            radius: ['80%'],
            top: "-20px",
            right: '35%',
            itemStyle: {
                borderRadius: 1,
                borderColor: '#ccc',
            },
            data: alarms.total_lock_alarm,

            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }]
    };


    const rms_active_selector = useSelector((state) => state.rmsActiveAlarmSlice)
    const acs_active_selector = useSelector((state) => state.acsActiveAlarmSlice)
    return (
        <>

            <div className="row g-2 mt-2 mb-2">
                <HomeGrid title={`Total Site`} value={alarms?.total_sites} route={'#'} color={`bg-dark text-white`} icon={`fa-solid fa-map`} class={`shadow-dark`} valueColor={'text-dark'} />
                <HomeGrid  title={`Total Lock`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.total_site : 0} route={`/sites`} color={`bg-info text-white`} icon={`fa-solid fa-lock`} class={`shadow-info`} valueColor={'text-info'} />
                <HomeGrid title={`Total Online`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.online_device : 0} route={'/online_sites'} color={`bg-green`} icon={`fa-regular fa-signal`} class={`shadow-green`} valueColor={'text-success'} />
                <HomeGrid title={`Total Offline`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.offline_device : 0} route={'/offline_sites'} color={`bg-secondary text-white`} icon={`fa-duotone fa-signal-slash`} class={`shadow-black`} valueColor={'text-gray'} />
                <HomeGrid title={`Sensor Disconnected`} value={equip_data?.total_sensor_disconnect??0} route={'#'} color={`bg-primary text-white`} icon={`fa-duotone fa-sensor-triangle-exclamation`} class={`shadow-primary`} />
                <HomeGrid title={`Door Open`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.door_open : 0} route={'rms_alarms?type=12'} color={`bg-primary text-white`} icon={`fa-regular fa-door-open`} class={`shadow-primary`} />
                {/* <HomeGrid title={`Door Open (ACS)`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.door_status : 0} route={'alarms?type=7'} color={`bg-purple text-white`} icon={`fa-regular fa-door-open`} class={`shadow-purple`} /> */}
                {/* <HomeGrid title={`Lock Open`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.lock_open_status : 0} route={'alarms?type=2'} color={`bg-orange text-white`} icon={`fa-regular fa-lock-open`} class={`shadow-orange`} valueColor={'text-warning'} />
                <HomeGrid title={`Security Breach`} value={alarms?.site_alarm?.length > 0 ? alarms?.site_alarm[0]?.breach_status : 0} route={'alarms?type=6'} color={`bg-red text-white`} icon={`fa-regular fa-user-secret`} class={`shadow-danger`} />
                 */}
                <HomeGrid title={`Mains Fail`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.mains_fail : 0} route={'rms_alarms?type=1'} color={`bg-danger text-white`} icon={`fa-regular fa-transformer-bolt `} class={`shadow-red`} />
                <HomeGrid title={`DC Low`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.dc_low : 0} route={'rms_alarms?type=2'} color={`bg-sapphire text-white`} icon={`fa-light fa-battery-quarter`} class={`shadow-sapphire`} />
                <HomeGrid title={`Overload`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.overloaded : 0} route={'rms_alarms?type=25'} color={`bg-warning text-white`} icon={`fa-regular fa-chart-line-up`} class={`shadow-orange`} valueColor={'text-warning'} />
                <HomeGrid title={`Load Disconnected`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.load_disconnect : 0} route={'rms_alarms?type=26'} color={`bg-purple text-white`} icon={`fa-regular fa-chart-line-down`} class={`shadow-purple`} valueColor={'text-warning'} />
                <HomeGrid title={`LLVD`} value={alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.llvd : 0} route={'rms_alarms?type=16'} color={`bg-pink text-white`} icon={`fa-regular fa-bolt-slash`} class={`shadow-pink`} valueColor={'text-pink'} />
                <HomeGrid title={`Battery Critical`} value={alarms?.rms_alarm_count?.length > 0 ? 0 : 0} route={'#'} color={`bg-danger text-white`} icon={`fa-regular fa-bolt-slash`} class={`shadow-danger`} valueColor={'text-danger'} />
            </div>


            <div className="row g-3">
                <div className="col-md-8">
                    <div className="row g-3">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
                            <div className="card ms-1 shadow-sm border-0 bg-cc">
                                <div class="card-header  border-bottom card-title bg-white"><i class="fa-light fa-circle-info me-1" />Rms Alarm Events ({!rms_active_selector?.loading && rms_active_selector.data?.active_alarm})
                                </div>
                                <div className="card-body" style={{ padding: '0px' }}>
                                    <RmsTimeline />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-3">
                            <div className="card ms-1 shadow-sm border-0 bg-cc">
                                <div class="card-header  border-bottom card-title bg-white"><i class="fa-light fa-circle-info me-1" />Access Alarm Events ({!acs_active_selector?.loading && acs_active_selector.data?.active_alarm})
                                </div>
                                <div className="card-body" style={{ padding: '0px' }}>
                                    <AcsTimeline />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">

               
                <div className="card ms-1 shadow-sm border-0 mb-2  bg-cc">
                        <div class="card-header  border-bottom card-title bg-white"><i class="fa-light fa-circle-info me-1" /> PG Running
                        </div>
                        <div className="card-body" style={{ padding: '0px',height:'200px' }}>
                            {/* <PgRunningStatus /> */}
                           
                            <div class="pg_on_count bg-main-color shadow-primary text-white"><i class="fa-regular">{alarms?.rms_alarm_count?.length > 0 ? alarms?.rms_alarm_count[0]?.pg_status : 0}</i></div>
                          
                        </div>
                    </div>

                    <div className="card ms-1 shadow-sm border-0">
                        <div class="card-header  border-bottom card-title bg-white"><i class="fa-light fa-circle-info me-1" /> Alarm Analytics  (ACS)
                        </div>
                        <div className="card-body" style={{ padding: '0px' }}>
                            <ReactEcharts ref={echarts_react_2} style={{ height: "300px" }} option={option1} />
                        </div>
                    </div>

                    

                    

                   

                </div>
                {/* 
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                            <HomeRmsAlarmGrid />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2">
                            <HomeLockAlarmGrid />
                        </div>
                    </div>
                </div> */}

            </div>
        </>
    )
}