export default function Footer(){
    return (
        <>
        <div className="footer-wrap">
            <footer className="footer" style={{backgroundColor:'#f4f6f9'}}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 col-sm-12 text-dark">
                    Copyright © 2022 <a className="text-decoration-none" href="javascript:void(0)">AJRA Tech</a>.
                  </div>
                  <div className="col-md-6 col-sm-12 text-md-end">
                   
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </>
    )
}